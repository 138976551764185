import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function Overlay(props) {
    const dataVal = useSelector((state) => state.dataValue)
    return (
        <div className="bg_overlay"
            onClick={props.onclick ? props.onclick : null}
            style={{
                background: dataVal?.common?.colors?.overlay_bg_color,
                opacity: dataVal?.common?.colors?.overlay_opacity / 100,
                position: props.position ? props.position : "absolute",
                zIndex: props.zindex ? props.zindex : 0
            }}
        ></div>
    )
}
export function Headings({ data, ishtml, type, classname, style, option }) {
    return (
        <>
            {ishtml ?
                <>
                    {option == "h1" ?
                        <h1 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h1>
                        :
                        option == "h2" ?
                            <h2 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h2>
                            :
                            option == "h3" ?
                                <h3 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h3>
                                :
                                option == "h4" ?
                                    <h4 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h4>
                                    :
                                    option == "h5" ?
                                        <h5 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h5>
                                        :
                                        <h6 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h6>
                    }
                </>
                :
                <>
                    {option == "h1" ?
                        <h1 className={classname} style={style}>
                            {data}
                        </h1>
                        :
                        option == "h2" ?
                            <h2 className={classname} style={style}>
                                {data}
                            </h2>
                            :
                            option == "h3" ?
                                <h3 className={classname} style={style}>
                                    {data}
                                </h3>
                                :
                                option == "h4" ?
                                    <h4 className={classname} style={style}>
                                        {data}
                                    </h4>
                                    :
                                    option == "h5" ?
                                        <h5 className={classname} style={style}>
                                            {data}
                                        </h5>
                                        :
                                        <h6 className={classname} style={style}>
                                            {data}
                                        </h6>
                    }
                </>
            }
        </>
    )
}
// export function HeadingH1({ data, ishtml, type, classname, style }) {
//     return (
//         <>
//             {ishtml ?
//                 <h1 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h1>
//                 :
//                 <h1 className={classname} style={style}>
//                     {data}
//                 </h1>
//             }
//         </>
//     )
// }
// export function HeadingH2({ data, ishtml, type, classname, style }) {
//     return (
//         <>
//             {ishtml ?
//                 <h2 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h2>
//                 :
//                 <h2 className={classname} style={style}>
//                     {data}
//                 </h2>
//             }
//         </>
//     )
// }
// export function HeadingH3({ data, ishtml, type, classname, style }) {
//     return (
//         <>
//             {ishtml ?
//                 <h3 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h3>
//                 :
//                 <h3 className={classname} style={style}>
//                     {data}
//                 </h3>
//             }
//         </>
//     )
// }
// export function HeadingH4({ data, ishtml, type, classname, style }) {
//     return (
//         <>
//             {ishtml ?
//                 <h4 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h4>
//                 :
//                 <h4 className={classname} style={style}>
//                     {data}
//                 </h4>
//             }
//         </>
//     )
// }
// export function HeadingH5({ data, ishtml, type, classname, style }) {
//     return (
//         <>
//             {ishtml ?
//                 <h5 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h5>
//                 :
//                 <h5 className={classname} style={style}>
//                     {data}
//                 </h5>
//             }
//         </>
//     )
// }
// export function HeadingH6({ data, ishtml, type, classname, style }) {
//     return (
//         <>
//             {ishtml ?
//                 <h6 className={classname} style={style} dangerouslySetInnerHTML={{ __html: data }}></h6>
//                 :
//                 <h6 className={classname} style={style}>
//                     {data}
//                 </h6>
//             }
//         </>
//     )
// }

export function Button({ data, type, classname, style, link, clickEvent }) {
    return (
        <>
            {type == "button" ?
                <button onClick={clickEvent} className={classname} style={style}>
                    {data}
                </button>
                :
                <Link to={link} className={classname} style={style}>
                    {data}
                </Link>
            }
        </>
    )
}