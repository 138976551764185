import React from "react";
import { Box, Button, Drawer, FormControl, FormHelperText, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../../../Assets/images/loader.svg";
import _ from "underscore"

export default function SelectBox({ data, handleSelectChange, fetchMoreData, hasNoMoreData, showLoader }) {
    console.log("data SelectBox: ", data)
    const [open, setOpen] = React.useState(false);
    const [newData, setnewData] = React.useState({});

    const toggleDrawer = (newOpen, opt) => () => {
        setOpen(newOpen);
        setnewData(opt)
    };

    console.log("hasNoMoreData", !_.isUndefined(hasNoMoreData) && hasNoMoreData)
    const DrawerList = (
        <div role="presentation" onClick={toggleDrawer(false)} id="scrollableDiv2" style={{ maxHeight: "400px", overflowY: "auto", width: 250 }}>
            <InfiniteScroll
                dataLength={data.items.length}
                next={() => fetchMoreData(newData)}
                hasMore={!_.isUndefined(hasNoMoreData) && hasNoMoreData == true ? false : true}
                loader={
                    showLoader &&
                    <div className="pagination_loader">
                        <img src={loader} alt="" width="50" />
                    </div>
                }
                endMessage={""}
                scrollableTarget="scrollableDiv2"
            >
                {data.items.map((val, index) => {
                    return (
                        <MenuItem key={index.toString()} value={val.value} onClick={() => handleSelectChange({ target: { value: val.value } }, data.name)}>{val.name}</MenuItem>
                    )
                })}
            </InfiniteScroll>
        </div>
    );


    return (
        <>
            {data.name == "product_category" ?
                // <Button onClick={toggleDrawer(true, data)}>Open drawer</Button>
                <TextField
                    id={data.name}
                    value={data.value}
                    label={data.label}
                    variant="outlined"
                    fullWidth
                    type={"text"}
                    sx={{ mb: 2 }}
                    onClick={toggleDrawer(true, data)}
                    InputLabelProps={{ shrink: true }}
                />
                :
                <FormControl fullWidth sx={{ mb: 2 }}>
                    {data.label !== "" &&
                        <InputLabel>{data.label}</InputLabel>
                    }
                    <Select
                        id={data.name}
                        value={data.value}
                        label={data.label}
                        onChange={(e) => handleSelectChange(e, data.name)}
                    >
                        {data.items && data.items.length > 0 && data.items.map((val, index) => {
                            return (
                                <MenuItem value={val.value} key={index.toString()}>{val.name}</MenuItem>
                            )
                        })}
                        {data.items && data.items.length == 0 ?
                            <MenuItem value="" disabled>No option</MenuItem>
                            : null
                        }
                    </Select>
                    {
                        data.name == "nav_menu" ?
                            <FormHelperText sx={{ m: 0, mt: 1 }}><a href="/admin/store/menus" target="_blank" style={{ color: "blue" }}>Add menu</a></FormHelperText>
                            : null
                    }
                </FormControl >
            }
            <Drawer open={open} onClose={toggleDrawer(false, "")}>
                {data.label !== "" &&
                    <Typography variant="h6" sx={{ p: 2 }}>{data.label}</Typography>
                }
                {DrawerList}
            </Drawer>
        </>
    )
}