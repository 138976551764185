import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, FormHelperText, Typography, FormGroup, Select, TextField, InputBase, Container, OutlinedInput, InputLabel, Chip, Alert, AlertTitle, Stack, Skeleton, LinearProgress, Avatar, Radio,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import update from "immutability-helper";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { convertToRaw } from "draft-js";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { alpha } from '@mui/material/styles';
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import htmlToDraft from "html-to-draftjs";

import { EditorState, ContentState } from "draft-js";

import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import { useDropzone } from 'react-dropzone';

import Seo from '../../../common/Seo/Seo';
import Fileupload from '../../../common/Fileupload/Fileupload';
import CustomEditor from '../../../common/CustomEditor/CustomEditor';
// import AutocompleteComp from '../../.././AutoComplete/Autocomplete';
import Variants from '../Variants/Variants';
import _, { indexOf } from "underscore";
import CreatableSelect from '../../../AutoComplete/CreatableSelect';
import * as Api from '../../../../Container/config/Apis';
import config from '../../../../Container/config';
import VariantModal from '../Variants/VariantModal';

import { useSnackbar } from 'notistack';
import DialogAlert from '../../../common/Alerts/Dialog';
import MediaFiles from '../../../common/Fileupload/MediaFIles';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { encryption } from '../../../../modules/module';
import productIcon from '../../../../Assets/images/no-result/product.svg';

const AddGiftProduct = () => {
    const param = useParams();
    let navigate = useNavigate()
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });

    const reduxVal = useSelector((state) => state.dataValue)

    const [loadingBar, setloadingBar] = React.useState(true);
    const [editProduct, setEditProduct] = useState(false);
    const [prodStatus, setProdStatus] = useState(1);
    const [modalType, setmodalType] = useState({
        editSku: false,
        ImageURL: false,
        variantImage: false,
        variantEdit: false,
        duplicate: false
    });
    const [duplicateObj, setDuplicateObj] = useState({
        product_id: param.id || "",
        newTitle: "",
        newStatus: "Active",
        copySku: false,
        copyInventoryQuantity: false,
        includeImages: false
    })
    const [images, setImages] = useState([])
    const [savebtnTxt, setsavebtnTxt] = useState("Save changes");
    const [activeOption, setactiveOption] = useState({});
    const [showError, setshowError] = useState(false)
    const [loading, setloading] = useState(false)
    const [body, setBody] = useState({
        title: "",
        body_html: "<p></p>",
        status: "Active",
        product_type: "gift_card",
        vender: "",
        tags: [],
        published: true,
        options: [
            {
                "name": "Denominations",
                "values": []
            }
        ],
        metafields_global_title_tag: "",
        metafields_global_description_tag: "",
        product_image: "",
        attachment: [],
        slug: "",
        category_ids: "",
        location_id: "gift_card",
    });
    const [value, setValue] = useState({
        product_type: "",
        category_ids: [],
        tags: []
    });
    const [newtag, setNewtag] = useState("");
    const [brand, setBrand] = useState(null);
    const [common, setCommon] = useState({
        isVariant: false
    });
    console.log("common: ", common)
    const [selected, setSelected] = useState({
        product_type: [],
        vender: [],
        category_ids: [],
        tags: [],
    })
    const [current, setCurrent] = useState("")
    const [urlImage, seturlImage] = useState("")
    const [selectedFile, setSelectedFile] = useState([])
    const [preview, setPreview] = useState([])
    const [optionArray, setoptionArray] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const [dataVal, setData] = useState("");
    const [showEditAll, setShowEditAll] = useState(false);
    const [contentState, setcontentState] = useState()
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [files, setFiles] = useState([]);

    //////////Variant block start

    const [variantModal, setVariantModal] = useState([])
    const [variant, setVariant] = useState([])
    let initOption = [
        { id: 1, optionName: "", new: true, optionValue: [{ id: 1, value: "" }], edit: false, error: { name: false, value: false } },
    ]
    const [prodOption, setprodOption] = useState(initOption);
    const [showAlert, setShowAlert] = useState(false)

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const [deleteType, setDelete] = useState({
        type: "",
        url: "",
        data: "",
        api: ""
    })
    const [showSkuError, setshowSkuError] = useState(false)
    const [product_limit, setproduct_limit] = useState(false)
    const [shipRocket, setshipRocket] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [popover, setPopover] = useState({
        reason: "",
        unavailable: {
            unavailable: 0
        },
        commited: {
            commited: 0
        },
        available: {
            adjust: 0,
            available: 0,
        },
        on_hand: {
            adjust: 0,
            on_hand: 0,
        },
    })

    const [denomination, setDenomination] = useState([
        { name: "10", value: "10" },
        { name: "25", value: "25" },
        { name: "50", value: "50" },
        { name: "100", value: "100" },
    ])

    const closeDialog = () => {
        setOpen(false);
        setDelete()
        setIsDelete(false)
        //     {
        //     type: "",
        //     url: "",
        //     data: "",
        //     api: ""
        // })
    }

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    const getChangedPos = useCallback((currentPos, newPos) => {
        console.log(currentPos, newPos);
        let newArr = array_move(preview, currentPos, newPos)
        console.log("newArr: ", newArr)
        setPreview([...new Set(newArr)]);
    }, [preview]);
    console.log("preview: ", preview)

    const addMore = () => {
        let arr = [...denomination]
        arr.push({ name: "", value: "" },)
        setDenomination(arr)
    }

    const deleteDemonination = (index) => {
        let arr = []
        denomination.map((x, i) => {
            if (i !== index) {
                arr.push(x)
            }
        })
        setDenomination(arr)
    }
    const handleChange = (e, index) => {
        let arr = []
        denomination.map((x, i) => {
            if (i == index) {
                arr.push({ name: e.target.value, value: e.target.value })
            } else {
                arr.push({ ...x })
            }
        })
        setDenomination(arr)
    }

    function optionVariants(optArr) {
        let array = []
        if (optArr.length > 0) {
            if (optArr.length === 2) {
                optArr[0].forEach(x => {
                    optArr[1].forEach(y => {
                        console.log([x, y]);
                        array.push([x, y])
                    });
                });
            } else if (optArr.length === 3) {
                optArr[0].forEach(x => {
                    optArr[1].forEach(y => {
                        optArr[2].forEach(z => {
                            console.log([x, y, z]);
                            array.push([x, y, z])
                        });
                    });
                });
            } else {
                optArr[0].forEach(x => {
                    console.log([x]);
                    array.push([x])
                });
            }
        }
        return array
    }

    console.log("variant: ", variant)
    console.log("optionList: ", optionList)
    console.log("optionArray: ", optionArray)

    // useEffect(() => {
    //     console.log("prodOption: ", prodOption)
    // }, [prodOption, variant])

    ////////Variant block end

    const updateTextDescription = async (state) => {
        await setEditorState(state);
    };
    const onContentStateChange = (contentState) => {
        setcontentState(draftToHtml(contentState))
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        setBody({ ...body, 'body_html': draftToHtml(contentState), 'metafields_global_description_tag': (value).slice(0, 320) });
        setData(draftToHtml(contentState))
    }

    const handleHtmlChange = (val) => {
        setData(val)
        const contentBlock = htmlToDraft(`<div>${val}</div>`);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            setBody({ ...body, 'body_html': val });

            // npm__react-simple-code-editor__textarea
            let textareaHeight = document.querySelector(".textEditorBox textarea")
            let preHeight = document.querySelector(".textEditorBox pre")
            console.log("textareaHeight: ", textareaHeight)
            setTimeout(() => {
                if (preHeight) {
                    console.log("textareaHeight preHeight: ", preHeight)
                    textareaHeight.style.height = `${preHeight.offsetHeight + 20}px`
                }
            }, 100);
        }
    }

    const filter = createFilterOptions();
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });
    const productTypesList = [];
    const organization = [];
    console.log("selectedFile: ", selectedFile)
    const onSelectFile = (e) => {
        e.preventDefault()
        const fileobj = [];
        let files = Array.from(e.target.files);
        console.log("files: ", files)
        fileobj.push(files);
        let reader;
        let selectedFiles = [...selectedFile]
        for (var i = 0; i < fileobj[0].length; i++) {
            if (files[i] && files[i].size <= 20000000) {
                selectedFiles.push(files[i])
                setSelectedFile(selectedFiles)
                setBody({ ...body, 'product_image': selectedFiles })
                reader = new FileReader();
                reader.readAsDataURL(fileobj[0][i]);
                reader.onload = event => {
                    if (!editProduct) {
                        preview.push(event.target.result);
                        setPreview([...new Set(preview)]);
                    }
                }
                e.target.value = ""
            } else {
                snack("Max file size is 20MB", 'error');
            }
        }
        if (editProduct) {
            let data = new FormData()
            if (selectedFiles.length > 0) {
                for (var i = 0; i < selectedFiles.length; i++) {
                    data.append('product_image', selectedFiles[i]);
                }
            }
            let bodyObj = {
                product_id: body.product_id
            }
            data.append("payload", encryption(bodyObj))

            // data.append("product_id", body.product_id)
            addVariantImageFn(Api.PostApiFormdata, config.PRODUCT_IMAGES, data, "")
        }
    }

    const deleteFn = async () => {
        if (deleteType !== undefined) {
            if (deleteType.type === "image") {
                deleteVariantImageFn(deleteType.api, deleteType.url, deleteType.data)
            } else if (deleteType.type === "option") {
                console.log("deleteType: ", deleteType)
                await updateVariantFn(Api.PostApi, `${config.PRODUCT_VARIANTS}/products/${body.product_id}/variants`, { "product_variant": deleteType.bodyData })
                await updateProductFn(Api.PutApi, `${config.PRODUCTS}/${body.product_id}`, deleteType.productBody, "variantUpdate")
            }
        }
        console.log("deleteType: ", deleteType)
    }
    //Delete image
    const deleteImage = (file, i) => {
        if (editProduct) {
            console.log("i: ", i)
            let imgData = images.filter((opt) => opt.src == file)[0]
            console.log("imgData: ", imgData)
            console.log("preview: ", preview)
            if (imgData) {
                setContent({ ...content, 'message': "You want to delete this image. This can't be undone." })
                setOpen(true)
                setDelete({ ...deleteType, 'type': "image", 'api': Api.DeleteApi, 'url': `${config.PRODUCT_IMAGES}/products/${imgData.product_id}/images/${imgData.id}`, 'data': i })
            } else {
                // preview.splice(i, 1)
                // setPreview([...new Set(preview)]);
            }
            // deleteVariantImageFn(Api.DeleteApi, `${config.PRODUCT_IMAGES}/products/${imgData.product_id}/images/${imgData.id}`, i)
        } else {
            let selectedFiles = [...selectedFile]
            let attachment = [...body.attachment]
            selectedFiles.splice(i, 1)
            attachment = attachment.filter((x) => x !== preview[i])
            preview.splice(i, 1)
            setPreview([...new Set(preview)]);
            setSelectedFile(selectedFiles)
            setBody({ ...body, 'product_image': selectedFiles, 'attachment': attachment })
        }
    }

    //handle create new select field
    const handleCreatableSelect = (e, val, reason, id) => {
        if (reason === "clear") {
            setBody({ ...body, [id]: "" })
        } else {
            let newArr = selected[id]
            if (val !== null) {
                if (typeof val === "string") {
                    if (val.length <= 30) {
                        let filterData = newArr.filter((opt) => opt === val)
                        if (filterData.length === 0) {
                            newArr.push({ title: val })
                            selected[id] = newArr
                            setSelected(selected)
                        }
                        setBody({ ...body, [id]: val })
                    }
                } else if (val && val.inputValue) {
                    let filterData = newArr.filter((opt) => opt === val.inputValue)
                    if (val.inputValue.length <= 30) {
                        if (filterData.length === 0) {
                            newArr.push({ title: val.inputValue })
                            selected[id] = newArr
                            setSelected(selected)
                        }
                        setBody({ ...body, [id]: val.inputValue })
                    }
                } else {
                    if (val.title.length <= 30) {
                        setBody({ ...body, [id]: val.title })
                    }
                }
            }
        }
    }

    function removeDuplicates(arr) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }

    const handleMultipleSelect = (e, val, reason, id) => {
        console.log(e, val, reason, id)
        if (reason === "clear") {
            setBody({ ...body, [id]: [] })
            setValue({ ...value, [id]: [] })
        } else {
            let newArr = selected[id]
            let array = []
            let newTitle = []
            let arrayTitle = []
            let valArray = []
            if (val !== null) {
                val.map((opt, i) => {
                    if (opt.id) {
                        valArray = _.where(val, { id: opt.id })
                    } else {
                        valArray = _.where(val, { title: opt.title } || opt)
                    }
                    if (valArray.length > 1) {
                        value[id].splice(i, 1)
                    } else {
                        if (opt.title) {
                            arrayTitle.push(opt)
                        } else {
                            if (id !== "category_ids") {
                                if (opt.length <= 10) {
                                    newTitle = [...selected[id]]
                                    arrayTitle.push({ title: opt })
                                    newTitle.push({ title: opt })
                                    setSelected({ ...selected, [id]: newTitle })
                                }
                            }
                        }
                    }
                    console.log("arrayTitle: ", arrayTitle)
                    setValue({ ...value, [id]: arrayTitle })
                    if (id == "tags") {
                        array.push(opt.title ? opt.title : opt)
                    } else {
                        array.push(opt.id)
                    }
                    console.log("opt====================================", opt)
                })
                console.log("array====================================", array)
                setBody({ ...body, [id]: removeDuplicates(array) })
            }
            if (reason === "removeOption") {
                if (val.length === 0) {
                    setValue({ ...value, [id]: [] })
                } else {
                    setValue({ ...value, [id]: val })
                }
            }
        }
    }
    console.log("value: ", value)
    console.log("selected: ", selected)

    const handleOnchange = (e) => {
        if (e.target.name == "title") {
            if (e.target.value.length <= 70) {
                setBody({ ...body, [e.target.name]: e.target.value })
            }
        } else {
            if (e.target.name == "weight" || e.target.name == "quantity") {
                let value = e.target.value
                var regex_num = value.replace(/^0+/, "");
                setBody({ ...body, [e.target.name]: regex_num })
            } else {
                setBody({ ...body, [e.target.name]: e.target.value })
            }
        }
    }
    const handleSEOchange = (e, length) => {
        if (e.target.value.length <= length) {
            setBody({ ...body, [e.target.name]: e.target.value })
        }
    }

    const handleSelectChange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
        if (e.target.name == "location_id") {
            let loc = shipRocket.filter((x) => (x?.id || x?.alias) == e.target.value)
            if (loc && loc.length > 0) {
                setpickUpLoc(loc[0]?.pickup_location || loc[0]?.alias)
            }
        }
    }

    const handleOnTitleChange = (e) => {
        if (!editProduct) {
            setBody({ ...body, 'slug': ((e.target.value).trim()).split(" ").join("-").toLowerCase(), "metafields_global_title_tag": e.target.value })
        }
    }

    const handleClose = (e, reason) => {
        console.log("e, reason: ", e, reason)
        if (reason !== "backdropClick") {
            setopenModal(false)
            setopenModal2(false)
            setsavebtnTxt("Save")
            setmodalTitle("")
            seturlImage("")
            setVariantModal([])
            let arr = []
            images.map((opt) => {
                arr.push({ ...opt, 'active': false })
            })
            setImages([...arr])
            setmodalType({})
            setshowSkuError(false)
            setShowEditAll(false)
            setactiveOption("")
        }
    }

    const getPickuplocation = (url, bodyData, type) => {
        Api.GetApi(url).then(res2 => {
            console.log("getpickuplocation res: ", res2)
            if (res2.error === true) {
                snack(res2.response.data.error, 'error');
            } else {
                if (res2.data.data) {
                    setshipRocket(res2.data.data)
                    if (res2.data.data.length > 0) {
                        if (res2.data.data.filter((x) => (x?.id || x?.alias) == bodyData?.location_id).length > 0) {
                            // setpickUpType("shiprocket")
                            let loc = res2.data.data.filter((x) => (x?.id || x?.alias) == bodyData?.location_id)
                            console.log("1loc: ", loc)
                            if (loc && loc.length > 0) {
                                setpickUpLoc(loc[0]?.pickup_location || loc[0]?.alias)
                            }
                        }
                    } else {
                        // setpickUpType("store")
                        setpickUpLoc(reduxVal && reduxVal?.store?.StoreAddress?.state || "")
                    }
                }
            }
        })
    }
    const getPickuplocationList = (url, type) => {
        Api.GetApi(url).then(res2 => {
            console.log("getpickuplocationlist res: ", res2)
            if (res2.error === true) {
                snack(res2.response.data.error, 'error');
            } else {
                if (res2.data.data) {
                    setshipRocket(res2.data.data)
                    if (res2.data.data.length > 0) {
                        setBody({ ...body, location_id: res2.data.data[0].id ? res2.data.data[0].id.toString() : res2.data.data[0]?.alias || "store" })
                        setpickUpLoc(res2.data.data[0]?.pickup_location || res2.data.data[0]?.alias)
                    } else {
                        setpickUpLoc(reduxVal && reduxVal?.store?.StoreAddress?.state || "")
                    }
                }
            }
        })
    }

    //GET PRODUCT BY ID
    const getProductByID = useCallback((id) => {
        Api.GetApi(`${config.PRODUCTS}/${id}`).then(async res => {
            console.log("getProductByID", res)
            setTimeout(() => {
                setloadingBar(false)
            }, 1000);
            if (res.error === true) {
                if (!_.isUndefined(res.response.data.error)) {
                    snack(res.response.data.error, 'error');
                } else {
                    snack(res.res.data.error, 'error');
                }
            } else {
                if (res.data.data) {
                    let category_id = []
                    let tags = []
                    let categories = []
                    let bodyData = res.data.data

                    setDuplicateObj({
                        ...duplicateObj,
                        product_id: bodyData?.product_id || param.id || "",
                        newTitle: `Copy of ${bodyData?.title}`,
                    })
                    if (bodyData.options.length == 0 && bodyData?.variants.length > 0) {
                        setPopover({
                            ...popover,
                            reason: "",
                            unavailable: {
                                unavailable: bodyData?.variants[0]?.inventory?.unavailable || 0
                            },
                            commited: {
                                commited: bodyData?.variants[0]?.inventory?.commited || 0
                            },
                            available: {
                                adjust: 0,
                                available: bodyData?.variants[0]?.inventory?.available || 0,
                            },
                            on_hand: {
                                adjust: 0,
                                on_hand: bodyData?.variants[0]?.inventory?.on_hand || 0,
                            }
                        })
                    }

                    // if (reduxVal && reduxVal?.store?.activeShippingSource) {
                    //     if (reduxVal?.store?.activeShippingSource?.shipping_source && reduxVal?.store?.activeShippingSource?.shipping_source == "Shiprocket") {
                    //         getPickuplocation(config.SHIPROCKET_LOCATIONS, bodyData, "Shiprocket")
                    //     } else {
                    //         getPickuplocation(config.GOSWIFT_PICKUPLOC, bodyData, "Goswift")
                    //     }
                    // }

                    if (res.data.data.options.length == 0) {
                        setshowOptions(true)
                        let variantFirst = bodyData.variants[0]
                        if (variantFirst.price) {
                            bodyData.price = variantFirst.price
                        }
                        if (variantFirst.compare_at_price) {
                            bodyData.compare_at_price = variantFirst.compare_at_price
                        }
                        if (variantFirst.is_tax_applicable) {
                            bodyData.is_tax_applicable = variantFirst.is_tax_applicable
                        }
                        if (variantFirst.weight) {
                            bodyData.weight = variantFirst.weight
                            bodyData.weight_unit = variantFirst.weight_unit
                        }
                        if (variantFirst.inventory_quantity) {
                            bodyData.track = true
                            bodyData.inventory_tracked = true
                            bodyData.quantity = variantFirst.inventory_quantity
                            bodyData.inventory_quantity = variantFirst.inventory_quantity
                            bodyData.inventory = variantFirst.inventory
                        }
                        if (variantFirst.sku) {
                            bodyData.sku = variantFirst.sku
                            bodyData.barcode = variantFirst.barcode
                        }
                    } else {
                        setshowOptions(false)
                    }
                    bodyData.category.length > 0 && bodyData.category.map((opt) => {
                        category_id.push(opt.category_id)
                    })
                    selected.category_ids.length > 0 && selected.category_ids.map((opt) => {
                        bodyData.category.length > 0 && bodyData.category.filter(((obj) => obj.category_id === opt.id)).map((val) => {
                            categories.push({ 'title': val.title, 'id': val.category_id })
                        })
                    })
                    selected.tags.length > 0 && selected.tags.map((opt) => {
                        bodyData.tags.length > 0 && bodyData.tags.filter(((obj) => obj.value == opt.title)).map((val) => {
                            tags.push({ 'title': val.value, 'id': opt?.id })
                        })
                    })
                    let prod_Option = []
                    if (bodyData.options.length > 0) {
                        let newObj = {}
                        let newObj2 = {}
                        let optionArr = []
                        bodyData.options.map((opt, index) => {
                            let optArr = []
                            opt.values && opt.values.length > 0 && opt.values.map((val, i) => {
                                optArr.push({ id: i + 1, value: val })
                            })
                            newObj = {
                                ...opt,
                                id: index + 1,
                                optionName: opt.name,
                                optionValue: optArr,
                                position: opt.position !== null ? opt.position : index + 1,
                                edit: true,
                                error: { name: false, value: false }
                            }
                            newObj2 = {
                                ...opt,
                                id: index + 1,
                                position: opt.position !== null ? opt.position : index + 1,
                            }
                            prod_Option.push(newObj)
                            optionArr.push(newObj2)
                            setprodOption([...prod_Option])
                            bodyData["options"] = optionArr
                        })
                    } else {
                        setprodOption([...initOption])
                    }

                    let arrayData = []
                    prod_Option.length > 0 && prod_Option.map((opt) => {
                        let array = []
                        opt.optionValue.map((obj) => {
                            array.push(obj.value)
                        })
                        arrayData.push(array)
                    })

                    let array2 = optionVariants(arrayData)
                    setOptionList(array2);

                    setoptionArray(arrayData)

                    let variantData = []
                    let variantData2 = []
                    bodyData.variants.length > 0 && bodyData.variants.map((opt, index) => {
                        let imgSrc = bodyData.images.filter((obj) => obj.id === opt.image_id)
                        variantData.push({ ...opt, 'checked': false, 'old_inventory_quantity': opt.inventory_quantity })
                        variantData2.push({
                            ...opt,
                            'img': !_.isUndefined(imgSrc) && !_.isNull(imgSrc) && imgSrc.length > 0 ? imgSrc[0].src : "",
                            'variant': array2[index],
                            'old_inventory_quantity': opt.inventory_quantity,
                            'quantity': opt.inventory_quantity,
                            'checked': false,
                            'track': opt.inventory_tracked
                        })
                    })
                    setVariant(variantData2)
                    setImages(_.sortBy(bodyData.images, "position"))
                    setValue({ ...value, 'category_ids': categories, 'tags': tags })
                    bodyData['product_image'] = []
                    // if (bodyData['weight']) {
                    //     bodyData['weight'] = bodyData['weight'].toString()
                    // }
                    bodyData['category_ids'] = category_id
                    bodyData['tags'] = tags
                    bodyData['slug'] = bodyData.handle
                    bodyData['variants'] = variantData
                    if (bodyData["options"].length == 0 && bodyData.variants.length > 0) {
                        bodyData['track'] = bodyData.variants[0]?.inventory_tracked
                        bodyData['inventory_tracked'] = bodyData.variants[0]?.inventory_tracked
                        bodyData['out_of_stock'] = bodyData.variants[0]?.out_of_stock
                    }

                    await handleHtmlChange(bodyData.body_html)
                    await setBody(bodyData)
                    setCurrent(bodyData)
                    let images = []
                    bodyData.images.map((img) => {
                        images.push({ 'src': img.src, 'id': img.id, 'position': img.position })
                    })
                    setPreview([...new Set(_.sortBy(images, "position"))]);
                }
                else {
                    snack("No record found. Please check the URL.", 'error');
                }
            }
        })
    }, [prodOption, variant, selected])
    console.log("prodOption: ", prodOption)
    console.log("reduxVal: ", reduxVal)


    //GET CATEGORIES
    const getCategoryList = useCallback(() => {
        Api.GetApi(config.PRODUCTS_COUNT).then(resp => {
            console.log("resp count: ", resp)
            if (resp.error === true) {
                snack(resp.response.data.error, 'error');
            } else {
                if (!param.id && (reduxVal && reduxVal?.plan && reduxVal?.plan?.plan?.limit && reduxVal?.plan?.plan?.limit?.product == "limited" && reduxVal?.plan?.plan?.limit?.product_limit <= resp.data.data)) {
                    setproduct_limit(true)
                    setloadingBar(false)
                } else {
                    setproduct_limit(false)
                    Api.GetApi(config.PRODUCT_CATEGORY_LIST).then(res => {
                        console.log("list res: ", res)
                        setTimeout(() => {
                            setloadingBar(false)
                        }, 1000);
                        if (res.error === true) {
                            snack(res.response.data.error, 'error');
                        } else {
                            // getProductTypeList()
                            // getBrandList()
                            // getTagsList()
                            if (res.data.data) {
                                if (param.id) {
                                    setloadingBar(true)
                                    setEditProduct(true)
                                    getProductByID(param.id)
                                } else {

                                    // Api.GetApi(config.SHIPROCKET_LOCATIONS).then(res2 => {
                                    //     console.log("SHIPROCKET_LOCATIONS res: ", res2)
                                    //     if (res2.error === true) {
                                    //         snack(res2.response.data.error, 'error');
                                    //     } else {
                                    //         if (res2.data.data) {
                                    //             setshipRocket(res2.data.data)
                                    //             if (res2.data.data.length > 0 && res2.data.data[0].id) {
                                    //                 setBody({ ...body, location_id: res2.data.data[0].id.toString() })
                                    //                 setpickUpLoc(res2.data.data[0]?.pickup_location)
                                    //             } else {
                                    //                 setpickUpLoc(reduxVal && reduxVal?.store?.StoreAddress?.state || "")
                                    //             }
                                    //         }
                                    //     }
                                    // })
                                }
                                let cateArr = selected.category_ids
                                let typeArr = selected.product_type
                                let venderArr = selected.vender
                                let tagsArr = selected.tags
                                if (res.data.data.category.length > 0) {
                                    res.data.data.category.map((opt) => {
                                        if (cateArr.filter((x) => x?.title == opt?.title).length == 0) {
                                            cateArr.push({ 'id': opt.category_id, 'title': opt.title })
                                        }
                                    })
                                }
                                if (res.data.data.product_type.length > 0) {
                                    res.data.data.product_type.map((opt) => {
                                        if (typeArr.filter((x) => x?.title == opt?.product_type).length == 0) {
                                            typeArr.push({ title: opt.product_type })
                                        }
                                    })
                                }
                                if (res.data.data.vendor.length > 0) {
                                    res.data.data.vendor.map((opt) => {
                                        if (venderArr.filter((x) => x?.title == opt?.vender).length == 0) {
                                            venderArr.push({ title: opt.vender })
                                        }
                                    })
                                }
                                if (res.data.data.product_tag.length > 0) {
                                    res.data.data.product_tag.map((opt) => {
                                        if (tagsArr.filter((x) => x?.title == opt?.value).length == 0) {
                                            tagsArr.push({ title: opt.value, id: opt.product_id })
                                        }
                                    })
                                }
                                setSelected({ ...selected, 'category_ids': cateArr, 'product_type': typeArr, 'vender': venderArr, 'tags': tagsArr })
                            }
                        }
                    })
                }
            }
        })
    }, [selected, reduxVal.plan])

    const addFromURL = () => {
        setopenModal2(true)
        setmodalType({ ...modalType, 'ImageURL': true })
        setsavebtnTxt("Add file")
        setmodalTitle("Add file from URL")
    }

    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || propName === "id" || propName === "product_id" || propName === "variant" || propName === "checked" || propName === "quantity" || propName === "created_at" || propName === "updated_at" || propName === "presentment_prices" || propName === "img" || propName === "Inventory_Item") {
                delete obj[propName];
            }
        }
        return obj
    }

    const updateEditproductVariant = (data) => {
        let bodyData = []
        let bodyObj = {}
        data.map((opt) => {
            bodyObj.compare_at_price = opt.compare_at_price
            bodyObj.is_tax_applicable = opt.is_tax_applicable
            bodyObj.old_inventory_quantity = opt.old_inventory_quantity
            bodyObj.option1 = opt.option1
            bodyObj.option2 = opt.option2
            bodyObj.option3 = opt.option3
            bodyObj.image_id = opt.image_id
            bodyObj.out_of_stock = opt.out_of_stock
            bodyObj.price = opt.price
            bodyObj.product_id = opt.product_id
            bodyObj.requires_shipping = opt.requires_shipping
            bodyObj.taxable = opt.taxable
            bodyObj.track = opt.track
            bodyObj.weight = opt.weight
            bodyObj.weight_unit = opt.weight_unit
            bodyObj.inventory_quantity = opt.inventory_quantity ? opt.inventory_quantity : opt.quantity
            bodyObj.barcode = opt.barcode
            bodyObj.sku = opt.sku
            bodyObj.product_variant_id = opt.variant_id
            bodyObj.inventory_item_id = opt.inventory_item_id
            bodyObj.inventory_tracked = opt.inventory_tracked

            bodyData.push({ ...bodyObj })
        })
        console.log("bodyData: ", bodyData)
        updateVariantFn(Api.PutApi, config.PRODUCT_VARIANTS_MULTIPLE, { "product_variant": bodyData })
        setVariant(data)

    }

    const handleOnURLchange = (e) => {
        seturlImage(e.target.value)
    }

    function cleanBody(obj) {
        for (var propName in obj) {
            if (propName === "product_id" || propName === "variants" || propName === "handle" || propName === "created_at" || propName === "updated_at" || propName === "category" || propName === "product_image" || propName === "published_at" || propName === "store_id" || propName === "_fivetran_deleted" || propName === "images" || propName === "published_scope" || propName === "slugs" || propName === "template_suffix" || propName === "metafields" || propName === "quantity" || propName === "inventory") {
                delete obj[propName];
            }
        }
        return obj
    }

    const findDuplicates = (array, property) => {
        const counts = array.reduce((acc, obj) => {
            acc[obj[property]] = (acc[obj[property]] || 0) + 1;
            return acc;
        }, {});

        return array.filter(obj => counts[obj[property]] > 1);
    };

    const saveProduct = async () => {

        if (body.title === "" || body.title.trim().length == 0) {
            setshowError(true)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
        else if (findDuplicates(denomination, 'value').length > 0 || (denomination.length == 1 && denomination[0].value == "") || denomination.filter(x => x.value == "0" || x.value == 0).length > 0) {
            setshowError(true)
            window.scrollTo({
                top: 500,
                behavior: 'smooth',
            });
        }
        else {
            // setloading(true)

            let data = new FormData();
            if (body.product_image.length > 0) {
                for (var i = 0; i < body.product_image.length; i++) {
                    data.append('product_image', body.product_image[i]);
                }
            }
            let bodyObj = {
                // vender: body.vender,
                title: body.title,
                body_html: body.body_html,
                status: body.status,
                slug: body.slug,
            }

            if (body.attachment.length > 0) {
                bodyObj['attachment'] = body.attachment
            }
            if (body.options.length > 0) {
                bodyObj['options'] = body.options
            }
            if (body.published !== "") {
                bodyObj['published'] = body.published
            }
            if (body.metafields_global_title_tag !== "") {
                bodyObj['metafields_global_title_tag'] = body.metafields_global_title_tag
            }
            if (body.metafields_global_description_tag !== "") {
                bodyObj['metafields_global_description_tag'] = body.metafields_global_description_tag
            }
            let optt = []
            denomination.filter((x) => x.value).map((x, i) => {
                optt.push(x.value)
            })
            bodyObj.options[0].values = optt
            bodyObj['tags'] = body.tags
            bodyObj['location_id'] = body.location_id || "gift_card"

            console.log("bodyObj", bodyObj)
            data.append("payload", encryption(bodyObj))

            saveProductFn(Api.PostApiFormdata, config.CREATE_GIFTCARD_PRODUCT, data, "add")
        }
    }

    const saveProductFn = async (api, url, data, type) => {
        console.log("body---data", data)
        await api(url, data).then(res => {
            console.log("res save product: ", res)
            if (res.error === true) {
                setloading(false)
                snack(res.response.data.error, 'error');
                // let errorArr = [...errors]
                // errorArr.push({ 'valid': false, 'error': res.response.data.error })
                // setErrors(errorArr)
                // setShowAlert(true)
                // window.scrollTo({
                //     top: 0,
                //     behavior: 'smooth',
                // });
            } else {
                snack(type === "add" ? "Product added successfully" : "Updated successfully", 'success');
                if (editProduct) {
                    setloading(false)
                    getProductByID(param.id)
                } else {
                    setTimeout(() => {
                        setloading(false)
                        navigate('/admin/products/list')
                    }, 2000);
                }
            }
        })
    }
    const updateProductFn = async (api, url, data, type) => {
        await api(url, data).then(res => {
            console.log("res update product: ", res)
            setloading(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack("Updated successfully", 'success');
                if (type === "variant" || type === "variantUpdate" || type === "optionPosition") {
                    getProductByID(param.id)
                }
                closeDialog()
            }
        })
    }

    //Update variant function
    const updateVariantFn = async (api, url, data) => {
        await api(url, data).then(res => {
            console.log("res update image: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                // getProductByID(param.id)
                if (editProduct && modalType.variantImage === true) {
                    let currentIndex = _.findIndex(variant, { 'variant_id': activeOption.variant_id })
                    if (currentIndex !== -1) {
                        variant[currentIndex] = activeOption
                        setVariant([...variant])
                        handleClose()
                    }
                } else if (editProduct && modalType.variantEdit) {
                    getProductByID(param.id)
                    handleClose()
                }
                if (modalType.duplicate) {
                    handleClose()
                    setloadingBar(true)
                    setTimeout(() => {
                        navigate(`/admin${config.PRODUCTS}/${res.data.data?.product_id}`)
                        getProductByID(res.data.data?.product_id)
                    }, 500);
                }
                // snack(res.data.data, 'success');
            }
        })
    }

    //Update variant image
    const checkSKUFn = async (data, type) => {
        await Api.PostApi(config.PRODUCT_SKU, data).then(res => {
            console.log("res SKU: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    setshowSkuError(true)
                } else {
                    setshowSkuError(false)
                    if (type == "") {
                        if (variant.length > 0) {
                            let newArray = []
                            variant.map((obj, index) => {
                                newArray.push({
                                    ...obj,
                                    sku: body.sku !== "" ? index === 0 ? body.sku : `${body.sku}-${index}` : "",
                                })
                            })
                            setVariant(newArray)
                        }
                    }
                }
            }
        })
    }

    //Add new variant image
    const addVariantImageFn = useCallback(async (api, url, data, type) => {
        await api(url, data).then(res => {
            console.log("res update image: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    // res.data.data.map((opt) => {
                    //     setImages([...images, opt])
                    //     preview.push(opt.src)
                    // })
                    if (type !== "addNew") {
                        let newArr = body.attachment ? [...body.attachment] : []
                        newArr.push(urlImage);
                        setBody({ ...body, 'attachment': newArr })
                        preview.push(urlImage);
                        setPreview([...new Set(preview)]);

                        seturlImage("")
                        handleClose()
                    }
                    getProductByID(param.id)
                    setSelectedFile([])
                    // setPreview([...new Set(preview)]);
                }
            }
        })
    }, [preview])

    //Delete variant image
    const deleteVariantImageFn = async (api, url, i) => {
        await api(url).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                preview.splice(i, 1)
                setPreview([...new Set(preview)]);
                closeDialog()
            }
        })
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        if (editProduct) {
            let data = {
                'id': images[dragIndex].product_id,
                'images': [
                    {
                        position: hoverIndex + 1,
                        id: images[dragIndex].id
                    },
                    {
                        position: dragIndex + 1,
                        id: images[hoverIndex].id
                    }
                ]
            }

            updateProductFn(Api.PutApi, `${config.PRODUCTS}/${images[dragIndex].product_id}`, data, "position")
        }
        setPreview((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]]
                ]
            })
        );
    }, [images]);

    const confirmDialog = () => {
        if (isDelete) {
            deleteProductFn()
        } else {
            deleteFn()
        }
    }

    const deleteProduct = () => {
        setContent({ ...content, 'message': "You want to delete this product. This can't be undone." })
        setOpen(true)
        setIsDelete(true)
    }
    const deleteProductFn = () => {
        Api.DeleteApi(`${config.PRODUCTS}/${param.id}`).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                closeDialog()
                setTimeout(() => {
                    navigate("/admin/products/list")
                }, 1000);
            }
        })
    }



    useEffect(() => {
        getCategoryList()
    }, [reduxVal && reduxVal?.plan]);
    // }, [editProduct]);
    console.log("current: ", current)
    console.log("product_limit: ", product_limit)
    console.log("shipRocket: ", shipRocket)
    console.log("denomination: ", denomination)

    useEffect(() => {
    }, [files, value]);

    return (
        <React.Fragment>
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                null
            }
            {product_limit ?
                <Container maxWidth="lg">
                    <Card sx={{ mb: 2, textAlign: "center", py: 5 }}>
                        <CardContent sx={{ py: 5 }}>
                            <Avatar sx={{ width: 120, height: 120, margin: "0 auto 20px" }} variant="square" src={productIcon}></Avatar>
                            <Typography variant="h5" component="div" gutterBottom>Oops! The maximum product limit has been reached.</Typography>
                            <Typography variant="p" component="div" gutterBottom>Please upgrade your plan to add unlimited products.</Typography>
                            <Button to="/admin/settings/plan" component={Link} variant='contained' sx={{ mt: 3 }}>Upgrade now</Button>
                        </CardContent>
                    </Card>
                </Container>
                :
                <Container maxWidth="lg">
                    <Box sx={{ mb: 2 }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item md={6}>
                                <Button component={Link} variant="text" color="secondary" to="/admin/products/list" startIcon={<ArrowBackIosIcon />}>
                                    {editProduct ?
                                        current && current.title :
                                        "Gift card product"
                                    }
                                </Button>
                            </Grid>
                            <Grid item md={6} sx={{ textAlign: "Right", pt: 0 }}>
                                {editProduct && current?.handle && current?.status !== "Draft" &&
                                    <Button variant="text" color="primary" sx={{ mr: 2 }}>
                                        <a target="_blank" href={current?.handle.startsWith("http") || current?.handle.startsWith("www.") ? current?.handle : `${config.PRODUCTS}/${current?.handle}`}>View</a>
                                    </Button>
                                }
                                {editProduct &&
                                    <Button variant="outlined" color="error" sx={{ mr: 2 }} onClick={deleteProduct}>
                                        Delete
                                    </Button>
                                }
                                <LoadingButton
                                    sx={{ whiteSpace: 'nowrap' }}
                                    color="primary"
                                    disabled={body?.options.length > 0 && editProduct && prodOption.filter((x) => x?.edit == false).length > 0 ? true : false}
                                    className='float_btn'
                                    onClick={saveProduct}
                                    loading={loading}
                                    loadingIndicator="Please wait..."
                                    variant="contained"
                                >
                                    {editProduct ? "Update" : "Save"}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container spacing={2} columns={12}>
                            <Grid item md={8}>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" gutterBottom>Product title</Typography>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="standard-basic"
                                                label=""
                                                name="title"
                                                autoFocus={true}
                                                value={body.title || ""}
                                                error={showError && (body.title === "" || body.title.trim().length == 0) ? true : false}
                                                helperText={showError && (body.title === "" || body.title.trim().length == 0) ? "Title can't be blank" : ""}
                                                onChange={handleOnchange}
                                                onBlur={handleOnTitleChange}
                                                size="small"
                                                variant="outlined" />
                                        </FormControl>
                                    </CardContent>
                                </Card>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" gutterBottom>Product description</Typography>
                                        <CustomEditor
                                            editorState={editorState}
                                            updateTextDescription={updateTextDescription}
                                            onContentStateChange={onContentStateChange}
                                            handleHtmlChange={handleHtmlChange}
                                            value={dataVal}
                                        />
                                    </CardContent>
                                </Card>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography variant="h6" component="h6" gutterBottom>Product images</Typography>
                                            <Button color="secondary" onClick={addFromURL}>Add from URL</Button>
                                        </Stack>
                                        <DndProvider backend={HTML5Backend}>
                                            <Fileupload
                                                preview={preview}
                                                onSelectFile={onSelectFile}
                                                deleteImage={deleteImage}
                                                getChangedPos={getChangedPos}
                                                moveCard={moveCard}
                                                editProduct={editProduct}
                                            />
                                            <Typography variant="caption" component="div" sx={{ fontStyle: 'italic' }} color="#999" gutterBottom>Max file size is 20MB</Typography>
                                        </DndProvider>
                                    </CardContent>
                                </Card>

                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="h6" gutterBottom>Denominations</Typography>
                                        <Box sx={{ pt: 1 }}>
                                            {denomination.map((opt, i) => {
                                                return (
                                                    <Grid container spacing={2} columns={12} alignItems="center" key={i} sx={{ mb: 2 }}>
                                                        <Grid item md={10}>
                                                            <FormControl fullWidth sx={{ m: 0 }} >
                                                                <OutlinedInput
                                                                    type="number"
                                                                    id="denomination"
                                                                    name="denomination"
                                                                    value={opt.value}
                                                                    onWheel={() => document.activeElement.blur()}
                                                                    onKeyDown={(e) => (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189 || e.code === "NumpadSubtract" || e.code === "NumpadAdd" || !(/[^0-9 .]/gi).test(e) || ["e", "E", "+", "-"].includes(e.key) || _.includes(Api.alphabets, e.key)) && e.preventDefault()}
                                                                    size="small"
                                                                    onChange={(e) => handleChange(e, i)}
                                                                    // onBlur={getprofit}
                                                                    sx={{ m: 0, width: '100%' }}
                                                                    placeholder='0.00'
                                                                    startAdornment={<InputAdornment position="start">{config.CURRENCY}</InputAdornment>}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={1}>
                                                            {denomination.length > 1 ?
                                                                <IconButton aria-label="edit" color="error" tabIndex={-1} onClick={() => deleteDemonination(i)}><DeleteIcon /></IconButton>
                                                                : null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                            {showError && findDuplicates(denomination, 'value').length > 0 ?
                                                <Typography variant="p" component="small" gutterBottom color={"error"}>Values must be unique.</Typography>
                                                : null
                                            }
                                            {showError && denomination.length == 1 && denomination[0].value == "" ?
                                                <Typography variant="p" component="small" gutterBottom color={"error"}>Can't be blank.</Typography>
                                                : null
                                            }
                                            {showError && denomination.filter(x => x.value == "0" || x.value == 0).length > 0 ?
                                                <Typography variant="p" component="small" gutterBottom color={"error"}>Value can't be 0.</Typography>
                                                : null
                                            }

                                            <Box sx={{ mt: 1 }}>
                                                <Button color="success" onClick={addMore}>+ Add more</Button>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>

                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Seo
                                            handleOnchange={handleSEOchange}
                                            body={body}
                                            page={config.PRODUCTS}
                                            title="metafields_global_title_tag"
                                            description="metafields_global_description_tag"
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="h6" gutterBottom >Product status</Typography>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <Select
                                                labelId="ProductStatus"
                                                id="productStatus"
                                                name="status"
                                                value={body.status}
                                                onChange={handleSelectChange}
                                                sx={{ mt: 0 }}
                                                defaultValue={prodStatus} size="small"
                                            >
                                                <MenuItem value={"Active"}>Publish</MenuItem>
                                                <MenuItem value={"Draft"}>Draft</MenuItem>
                                            </Select>
                                            <FormHelperText sx={{ ml: 0 }}>This product will be {body.status === "Active" ? "available to" : "hidden from"} your store.</FormHelperText>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                                <Card sx={{ mb: 1 }}>
                                    <CardContent sx={{ pt: 0 }}>
                                        <Typography variant="h6" component="h6" gutterBottom>Product type</Typography>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                id="standard-basic"
                                                label=""
                                                name=""
                                                autoFocus={true}
                                                value={"Gift cards"}
                                                disabled
                                                size="small"
                                                variant="outlined" />
                                        </FormControl>
                                    </CardContent>
                                    <CardContent sx={{ pt: 0 }}>
                                        <Typography variant="h6" component="h6" gutterBottom>Tags</Typography>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <CreatableSelect
                                                value={value.tags}
                                                handleCreatableSelect={handleMultipleSelect}
                                                list={selected.tags}
                                                id={"tags"}
                                                multiple={true}
                                                type="Select tags"
                                                showAlert={showAlert}
                                                body={body}
                                            />
                                        </FormControl>
                                        <Typography variant="caption" component="div" sx={{ fontStyle: 'italic' }} color="#999" gutterBottom>*Type and press enter to add new tag</Typography>
                                    </CardContent>
                                </Card>

                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Divider sx={{ my: "15px" }}></Divider>
                                <Box sx={{ textAlign: "left" }}>
                                    <Button variant="text" color="error" tabIndex={-1} onClick={() => navigate("/admin/products/list")}>Cancel</Button>
                                    <LoadingButton
                                        sx={{ ml: 1, whiteSpace: 'nowrap' }}
                                        color="primary"
                                        disabled={body?.options.length > 0 && editProduct && prodOption.filter((x) => x?.edit == false).length > 0 ? true : false}
                                        onClick={saveProduct}
                                        loading={loading}
                                        loadingIndicator="Please wait..."
                                        variant="contained"
                                    >
                                        {editProduct ? "Update" : "Save"}
                                    </LoadingButton>
                                    {/* <Button variant="contained" color="primary"  onClick={saveProduct} sx={{ ml: 1 }}>{editProduct ? "Update product" : "Save product"}</Button> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            }

            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
            />
        </React.Fragment>
    );
}

export default AddGiftProduct