export default {
    CURRENCY: '₹',
    CURRENCY_CODE: "INR",
    PENDING: "Pending",
    CONFIRMED_LABEL: "Confirmed",
    TRANSIT_LABEL: "In transit",
    IN_TRANSIT: "In-transit",
    CONFIRMED: "Confirmed",
    OUT_FOR_DELIVERY: "Out-for-delivery",
    DELIVERED_LABEL: "Delivered",
    DELIVERED: "Delivered",
    CANCELED: "Canceled",
    SHIPPED_LABEL: "Shipped",
    SHIPPED: "Shipped",
    PRODUCTS_LIST: '/products/list',
    PRODUCTS: '/products',
    CATEGORIES: '/categories',
    PAGES: '/pages',
    BLOGS: '/blogs',
    PRODUCTS_BULK_DELETE: '/products/bulk-delete',
    PRODUCTS_COUNT: '/products/product/count',
    PRODUCT_CSV: '/products/csv/download',
    PRODUCT_DUPLICATE: '/products/duplicate',
    CUSTOMER_PRODUCT_SEARCH: '/customers/product/search',
    CUSTOMER_PRODUCT_SEARCH_HISTORY: '/customers/product/search-history',
    PRODUCT_CATEGORY_LIST: '/category/categorylist',
    CUSTOMER_CATEGORY_LIST: '/customers/categorylist',
    CATEGORY_LIST: '/category/list',
    CATEGORY: '/category',
    BULK_CATEGORY_DELETE: '/category/delete/multiple',
    CATEGORY_DETAIL: '/category/categoryproduct',
    CATEGORY_IMAGE: '/category/image',
    CATEGORY_MOBILE_IMAGE: '/category/mobileImage',
    PRODUCT_TYPE_LIST: '/products/product-type/list',
    BRAND_LIST: '/products/vendor/list',
    TAGS_LIST: '/products/product-tag/list',
    PRODUCT_VARIANTS: '/product_variants',
    PRODUCT_VARIANTS_MULTIPLE: '/product_variants/multiple',
    PRODUCT_OUTOFSTOCK: '/products/out-of-stock',
    PRODUCT_VARIANTS_LIST: '/product_variants/list',
    PRODUCT_VARIANTS_COUNT: '/product_variants/count',
    PRODUCT_IMAGES: '/product_images',
    PRODUCT_SKU: '/products/product/sku',

    ITEM_NVENTORY_HISTORY: '/inventory_items/history/',
    ITEM_INVENTORY: '/inventory_items/adjust/',

    USER_LIST: '/customers',
    USER_SEND_INVITE: '/customers/send_invite',
    USER_LAST_ORDER: '/customers/lastOrder',
    USER_ORDER: '/customers/customerorder',
    USER_RESET_PASSWORD_EMAIL: '/customers/send_reset_password_mail',
    USER_ADDRESS: '/customer_address',
    DISCOUNT_RULE: '/discount_rule',
    DISCOUNT_RULE_COUNT: '/discount_rule/count',
    DISCOUNT_RULE_ACTION: '/discount_rule/action',
    PAYMENT_GATEWAY: '/store/gateway',
    PAYMENT_GATEWAY_ACTIVE: '/store/active',
    SUBSCRIPTION_PLAN: '/subscription/plans',
    SUBSCRIPTION: '/subscription',
    PLAN_SUBSCRIBE: '/subscription/plan_subscribe',
    ACTIVE_STORE_PLAN: '/subscription/store',
    SUBSCRIPTION_CANCEL: '/subscription/cancel',
    SUBSCRIPTION_UPDATE: '/subscription/update_subscription',
    VERIFY_PLAN: '/subscription/verify-plan-subscribe',
    STAFF: '/staff',
    PERMISSION_LIST: '/staff/permissions',
    STAFF_PERMISSION_LIST: '/staff/permission/list',
    ORDERS: '/order',
    ORDER_FULFILL: '/order/fulfillment',
    ORDER_CSV: '/order/csv/download',
    ORDER_PDF: '/order/pdf/download',
    ORDER_CANCEL: '/order/cancel',
    ORDER_FULFILLMENT: '/order/fulfillment',
    ORDER_ADDTRACKING: '/order/add-tracking',
    CANCEL_FULFILLMENT: '/order/fulfillment-cancel',
    ORDER_COMPLETE: '/order/complete',
    ORDER_STATUS: '/order/status',
    REFUND: '/refund',
    REFUND_CALCULATE: '/refund/calculatePrice',
    DRAFT_ORDERS_LIST: '/draftOrders/list',
    EXCHANGE_ORDERS_LIST: '/returnExchange/requests',
    CHECKOUT_ORDERS_LIST: '/order/abundant-list',
    CHECKOUT_ORDERS_DETAIL: '/order/detail',
    CHECKOUT_CANCEL_MAIL: '/order/cancel-mail',
    CHECKOUT_SEND_MAIL: '/order/send-reminder-email',
    DRAFT_ORDERS: '/draftOrders',
    DRAFT_ORDER_TOTAL: '/draftOrders/totalAmount',
    DRAFT_SEND_INVOICE: '/draftOrders/sendinvoice',
    STORE: '/store',
    STORE_SEARCH: '/store/search',
    CUSTOMER_LOGIN: '/customers/login',
    CUSTOMER_REGISTER: '/customers/register',
    CUSTOMER_CAPTCHA_VERIFY: '/customers/captcha/verify',
    CUSTOMER_VERIFY: '/customers/verify',
    CUSTOMER_FORGOT_PASSWORD: '/customers/forgot-password',
    CART_TOKEN: '/customers/token',
    CART: '/cart',
    PRODUCT_PRODUCT_LIST: '/products/product-list',
    CUSTOMER_PRODUCT_LIST: '/customers/product/productlist',
    CUSTOMER_PRODUCT_DETAIL: '/customers/product/detail',
    CUSTOMER_CATEGORY_DETAIL: '/customers/category',
    CUSTOMER_PRODUCT_DESCRIPTION: '/customers/product/description/products',
    CUSTOMER_ORDER_DETAIL: '/customers/order-detail',
    MAINTENANCE_MODE: '/customers/maintenance',
    PAYTM_PAYMENT: '/payment/paytm',
    AUTHOR_LIST: '/blog/author',
    BLOG_CATEGORIES: '/blog/category',
    BLOG_POST: '/blog/post',
    BLOG_POST_TAGS: '/blog/post/tags',
    BLOG_POST_PUBLISH: '/blog/post/publish',
    CUSTOMER_BLOG_POST: '/customers/post',
    CUSTOMER_BLOG_POST_SLUG: '/customers/post/slug',
    PAGE: '/page',
    PAGE_DUPLICATE: '/page/duplicate',
    MENU: '/menu',
    CUSTOMER_MENU: '/customers/menu',
    MENU_ITEM: '/menu/menu-item',
    MENU_ITEM_REORDER: '/menu/menu-item/re-order',
    PREFERENCE: '/preference',
    PREFERENCE_REMOVEIMG: '/preference/remove-images',
    CUSTOMER_PREFERENCE: '/customers/preferences',
    REDIRECTS: '/redirects',
    SHIPPING_METHOD: '/store/shipping-method',
    TEMPLATE: '/template',
    TEMPLATE_ALL: '/template/all/list',
    TEMPLATE_STORE_LIST: '/template/store/list',
    TEMPLATE_DETAIL: '/template/detail',
    TEMPLATE_GALLERY1: '/template/gallery',
    TEMPLATE_GALLERY: '/template/gallery/list',
    CUSTOMER_PAGE_DETAIL: '/customers/page/detail',
    ADMIN_RESET_PASSWORD: '/users/resetPassword',
    CUSTOMER_CHANGE_PASSWORD: '/customers/change-password',
    CUSTOMER_PROFILE: '/customers/profile',
    CUSTOMER_ORDER: '/customers/order/detail',
    CUSTOMER_ADDRESS: '/customers/address',
    USER_PROFILE: '/users/profile',
    USER_PROFILE_IMAGE: '/users/profile/remove-profile-image',
    DASHBOARD_OVERVIEW: '/dashboard/overview',
    DASHBOARD_DETAIL: '/dashboard/detail',
    DASHBOARD_ORDERS: '/dashboard/order',
    DASHBOARD_STEPS: '/dashboard/setup-steps',
    DASHBOARD_SKIP_STEPS: '/dashboard/skip-step',
    CHECKOUT_SETTING: '/checkout-setting',
    CHECKOUT_SETTING_REMOVE_IMAGE: '/checkout-setting/remove-images',
    EMAIL_TEMPLATE: '/email-template',
    EMAIL_TEMPLATE_RESET: '/email-template/reset-to-default',
    EMAIL_TEMPLATE_PREVIEW: '/email-template/preview',
    REPORT_CUSTOMER: '/report/customer',
    REPORT_DISCOUNT: '/report/discount',
    REPORT_BYPRODUCTS: '/report/product',
    REPORT_BYPRODUCTVARIANT: '/report/product-variant',
    REPORT_PRODUCTS: '/report/sales-by-product',
    REPORT_PRODUCTS_SUMMARY: '/report/sales/product/summary',
    REPORT_CUSTOMER_SUMMARY: '/report/customer/summary',
    REPORT_LOCATION: '/report/location',
    REPORT_LOCATION_STATES: '/report/states',
    REPORT_LOCATION_SUMMARY: '/report/location/summery',
    CUSTOMER_LOCATION: '/customers/location',
    REPORT_DISCOUNT_SUMMARY: '/report/discount/summary',
    RESEND_VERIFY_EMAIL: '/users/verifyUser',
    DOMAIN_VERIFICATION: '/domain-verification',
    SHIPROCKET_CONNECT: '/shiprocket/connect',
    SHIPROCKET_DETAIL: '/shiprocket/details',
    SHIPROCKET_LOCATIONS: '/shiprocket/pickup-locations',
    GETTAX: '/tax/get',
    UPDATETAX: '/tax',
    RESETTAX: '/tax/reset',
    ENABLETAX: '/tax/enable',
    SHIPPING: '/shipping',
    SHIPPING_UPDATE: '/shipping/updateStatus',
    GOSWIFT_CONNECT: '/goSwift/connect',
    GOSWIFT_PICKUPLOC: '/goSwift/pickup-locations',

    UPDATECOUNTRYTAX: '/tax/updateCountryTax',
    STORE_CONNECT: '/store/connect',
    STORE_CONNECT_CALLBACK: '/store/connect-callback',
    STORE_DISCONNECT: '/store/disconnect',
    RETURNEXCHANGE: "/returnExchange/settings",
    UPDATERETURNEXCHANGE: "/returnExchange",
    CREATERETURNEXCHANGE: "/customers/returnExchange/createRequest",
    GENERATE_PICKUP: "/returnExchange/generatePickup",
    RETURN_COMPLETE: "/returnExchange/returnComplete",
    RETURN_STATUS: "/returnExchange/status",  
    GST: '/gst',   
    CREATE_RETURN_ORDER: '/returnExchange/create-draft-order',   
    TWITTER_LOGIN: '/social-media-marketing/twitter/login',   
    YOUTUBE_LOGIN: '/social-media-marketing/youtube/login',   
    SOCIAL_MEDIA: '/social-media-marketing',   
    TWITTER_AUTH_ILNK: '/social-media-marketing/twitter/auth-link',   
    YOUTUBE_AUTH_ILNK: '/social-media-marketing/youtube/auth-link',   
    TWITTER_TWEET: '/social-media-marketing/twitter/tweet',   
    CREATE_GIFTCARD: '/giftcard/create',   
    GIFTCARD_LIST: '/giftcard/list',   
    GIFTCARD: '/giftcard',  
    GIFTCARD_DISABLED: '/giftcard/disabled',  
    CUSTOMER_GIFTCARD: '/customers/giftcard',  
    CREATE_GIFTCARD_PRODUCT: '/giftcard/create/as-product',  
    UPDATE_GIFTCARD_PRODUCT: '/giftcard/update/product',  

    YOUTUBE_UPLOAD: '/social-media-marketing/youtube/upload',   

    PUBLIC_KEY: 'store/public-api-key',
    PUBLIC_KEY_GENERATE: 'store/public-api-key/genrate',
}