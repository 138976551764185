import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, FormHelperText, Typography, FormGroup, Select, TextField, InputBase, Container, OutlinedInput, InputLabel, Chip, Alert, AlertTitle, Stack, Skeleton, LinearProgress, Avatar, Radio, styled,
    alpha
} from '@mui/material';
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import _, { indexOf } from "underscore";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import config from "../../../../Container/config";
import * as Api from "../../../../Container/config/Apis"
//  Date time
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import dayjs from 'dayjs';
import AddUser from '../../Users/AddUser/AddUser';
import PopupModal from '../../../common/PopupModal/PopupModal';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useSelector } from 'react-redux';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.0),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(3),
        // width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    display: 'flex',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        // borderRadius:"10px",
        // background: "#f7f8fa",
        margin: 0,
        // display: 'flex',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    '&.Mui-error': {
        border: "1px solid #d32f2f",
        backgroundColor: "rgba(255, 0, 0, 0.05)"
    },
}));

const AddGiftCard = () => {
    const param = useParams();
    const storeVal = useSelector((state) => state.dataValue)
    let navigate = useNavigate()
    const [showDialog, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [body, setBody] = useState({
        status: "active"
    })
    const [giftCard, setgiftCard] = useState("")
    const [is_expire, setISexpire] = useState("no")
    const [userList, setuserList] = useState([]);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });
    const [params, setParams] = useState({
        search: "",
        page: 1
    })
    const [hideSearch, setHideSearch] = useState(true)
    const [selectedUser, setSelectedUser] = useState({})
    const [openModal2, setOpenModal2] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const generategiftCard = () => {
        var randomstring = Math.random().toString(36).slice(-8).toLowerCase() + Math.random().toString(36).slice(-8).toLowerCase();
        setgiftCard(randomstring)
        setBody({ ...body, 'title': randomstring })
    }

    const ModalClose = () => {
        setOpenModal2(false)
        setBody({
            ...body,
            user: {
                ...selectedUser
            }
        })
    }

    //Get user list
    const getUserList = (param) => {
        Api.GetApi(`${config.USER_LIST}${param}`).then(res => {
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []
                if (res.data.data) {
                    res.data.data.map((opt) => {
                        arr.push({ ...opt, active: false })
                    })
                    setuserList(arr)
                }
            }
        })
    }
    const updateFn = (data) => {
        Api.PostApi(config.CREATE_GIFTCARD, data).then(res => {
            console.log("res CREATE_GIFTCARD: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                navigate("/admin/gift-card")
            }
        })
    }

    const handleExpire = (e) => {
        setISexpire(e.target.value)
    }
    const handleOnChange = (e) => {
        setBody({
            ...body,
            [e.target.name]: e.target.value
        })
    }
    const handleChecked = (e) => {
        setBody({
            ...body,
            [e.target.name]: e.target.checked
        })
    }

    const handleUserSearch = useCallback((e) => {
        const value = e.target.value.replace('&', '%26');
        setParams({ ...params, 'search': value })
        if (value !== "") {
            getUserList(`?page=${params.page}&search=${value}`)
            setHideSearch(false)
        } else {
            getUserList(`?page=${params.page}`)
            setHideSearch(true)
        }
    }, [params])

    const selectUser = (data) => {
        if (data.addresses && data.addresses.length > 0) {
            if (data.addresses.filter((opt) => opt.is_default).length == 0) {
                let activeAddr = [...data.addresses]
                activeAddr[0]["is_default"] = true
                setSelectedUser({ ...data, addresses: activeAddr, shipping_address: activeAddr[0] })
            } else {
                setSelectedUser({ ...data, shipping_address: data.addresses.filter((opt) => opt.is_default)[0] })
            }
        } else {
            setSelectedUser(data)
        }
        setBody({ ...body, 'user': data })
        setHideSearch(true)
        setParams({ ...params, 'search': "" })
    }

    const updategiftCard = (e) => {
        if (e.target.value.length <= 16) {
            setgiftCard(e.target.value)
        }
    }
    const removeUser = () => {
        setSelectedUser({})
        setBody({ ...body, 'user': {} })
    }

    const handleDateChange = (newValue) => {
        setBody({ ...body, 'expire_at': newValue });
    };

    const saveCard = () => {
        let data = {}
        if (giftCard && body.value && body.user && body.user.customer_id) {
            setError(false)
            data = {
                code: giftCard,
                value: body.value,
                status: body.status,
                expire_at: is_expire == "yes" ?
                    _.isObject(body.expire_at) ?
                        moment((body.expire_at.$d ? body.expire_at.$d : body.expire_at._d ? body.expire_at._d : body.expire_at)).format() :
                        body.expire_at :
                    null,
                customer_id: body.user.customer_id,
                note: body.note || "",
                sendMail: body.sendMail || false
            }
            console.log("data", data)
            updateFn(data)
        } else {
            setError(true)
        }
    }

    const onAccept = (newValue) => {
        if (newValue && newValue.isBefore(dayjs())) {
            setBody({ ...body, ["expire_at"]: dayjs() });
        }
    }   

    useEffect(() => {
        if (storeVal?.new_user) {
            ModalClose()
            let data = {
                target: {
                    value: storeVal?.new_user
                }
            }
            handleUserSearch(data)
        }
    }, [storeVal?.new_user])

    console.log("body giftCard", giftCard)
    console.log("body", body)
    console.log("is_expire", is_expire)


    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Button component={Link} variant="text" color="secondary" to="/admin/gift-card" startIcon={<ArrowBackIosIcon />}>Create gift card
                    </Button>
                </Box>
                <Box>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={7}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item md={6}>
                                            <Typography variant="h6" component="div" gutterBottom>Gift card code</Typography>
                                        </Grid>
                                        <Grid item md={6} sx={{ textAlign: "Right" }}>
                                            <Button variant="text" onClick={generategiftCard}> Generate code </Button>
                                        </Grid>
                                    </Grid>
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <TextField
                                            id="code"
                                            name="code"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            variant="outlined"
                                            autoFocus={true}
                                            value={giftCard}
                                            maxLength="20"
                                            onChange={updategiftCard}
                                            error={error && !giftCard ? true : false}
                                            helperText={error && !giftCard ? `Please enter code.` : ""}
                                        />
                                    </FormControl>

                                    <Typography variant="h6" component="div" gutterBottom>Value</Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <FormControl sx={{ m: 0 }} fullWidth>
                                            <TextField
                                                label=""
                                                type="number"
                                                id="value"
                                                name="value"
                                                onChange={handleOnChange}
                                                onWheel={() => document.activeElement.blur()}
                                                onKeyDown={(e) => (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189 || e.keyCode === 189 || e.code === "NumpadSubtract" || e.code === "NumpadAdd" || !(/[^0-9 .]/gi).test(e) || ["e", "E", "+", "-"].includes(e.key) || (body.value_type === "percentage" && ["."].includes(e.key)) || _.includes(Api.alphabets, e.key)) && e.preventDefault()}
                                                value={body.value}
                                                size="small"
                                                sx={{ m: 0, width: '100%' }}
                                                error={error && !body.value ? true : false}
                                                helperText={error && !body.value ? `Please enter value.` : ""}
                                                InputProps={
                                                    {
                                                        startAdornment: <InputAdornment position="start">{config.CURRENCY}</InputAdornment>
                                                    }
                                                }
                                                placeholder={"0.00"}
                                            />
                                        </FormControl>
                                    </Box>

                                    <Box>
                                        <FormControlLabel control={<Checkbox color="secondary" onChange={handleChecked} id="sendMail" name='sendMail' value={body.sendMail} checked={body.sendMail} />} label={"Send gift card now"} />
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" sx={{ mb: 2 }} component="div" gutterBottom>Expiration date</Typography>

                                    <Box>
                                        <FormControlLabel control={<Radio color="secondary" onChange={handleExpire} id="no" value="no" checked={is_expire == "no"} />} label={"No expiration date"} />
                                    </Box>
                                    <Box>
                                        <FormControlLabel control={<Radio color="secondary" onChange={handleExpire} id="yes" value="yes" checked={is_expire == "yes"} />} label={"Set expiration date"} />
                                    </Box>
                                    {is_expire == "yes" ?
                                        <Box sx={{ mt: 2 }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <MobileDateTimePicker
                                                    value={body.expire_at}
                                                    onChange={(e) => handleDateChange(e)}
                                                    label="Select date"
                                                    onError={console.log}
                                                    minDate={dayjs(new Date())}
                                                    minTime={body.expire_at < new Date() && dayjs(new Date()).subtract(1, "minute")}
                                                    inputFormat="DD/MM/YYYY HH:mm A"
                                                    mask="____/__/__ __:__ _M"
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                    onAccept={onAccept}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        : null
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            {/* <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="h6" gutterBottom >Status</Typography>
                                    <FormControl fullWidth sx={{ m: 0 }}>
                                        <Select
                                            labelId="ProductStatus"
                                            id="productStatus"
                                            name="status"
                                            value={body.status}
                                            onChange={handleOnChange}
                                            sx={{ mt: 0 }}
                                            size="small"
                                        >
                                            <MenuItem value={"active"}>Publish</MenuItem>
                                            <MenuItem value={"redeemed"}>Redeemed</MenuItem>
                                            <MenuItem value={"expired"}>Expired</MenuItem>
                                        </Select>
                                    </FormControl>
                                </CardContent>
                            </Card> */}
                            <Card sx={{ mb: 2, overflow: "inherit" }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <Typography variant="h6" component="div" gutterBottom> User  </Typography>
                                        <Button onClick={() => setOpenModal2(true)}>Add user</Button>
                                    </Box>
                                    <Box sx={{ position: 'relative' }}>
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon color="grey" sx={{ fontSize: "18px !important" }} />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search user"
                                                inputProps={{
                                                    'aria-label': 'search',
                                                    value: params.search
                                                }}
                                                autoComplete="off"
                                                name="searchCustomer"
                                                onChange={handleUserSearch}
                                                error={error && (!body.user || !body.user.customer_id) ? true : false}
                                            />
                                        </Search>
                                        {error && (!body.user || !body.user.customer_id) ?
                                            <Typography variant='caption' color={"error"} sx={{ ml: 2 }}>Please add user</Typography>
                                            : null
                                        }
                                        {!hideSearch &&
                                            <Box className='user_search_box'>
                                                {userList.length > 0 ?
                                                    <>
                                                        {userList.map((user, index) => {
                                                            return (
                                                                <Button key={index} color="secondary" sx={{ textAlign: "left", justifyContent: 'flex-start' }} className="w-100" onClick={() => selectUser(user)}>
                                                                    <Typography variant="body1" component="div">
                                                                        <strong>{user?.name || `${user?.first_name} ${user?.last_name ? user?.last_name : ""}` || "Guest user"}</strong> <br />
                                                                        <small style={{ color: "#999" }}>({user.email})</small>
                                                                    </Typography>
                                                                </Button>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <Typography variant="h6" component="h6" sx={{ p: 3, textAlign: "center" }} gutterBottom>No user found!</Typography>
                                                }
                                            </Box>
                                        }
                                    </Box>
                                    {!_.isEmpty(selectedUser) &&
                                        <Grid container spacing={2} columns={12} sx={{ mt: 1 }}>
                                            <Grid item md={9}>
                                                <Box>
                                                    <Button variant="text" component={Link} to={`/admin/users/${selectedUser?.customer_id || ""}`} className="noHover" sx={{ p: 0, justifyContent: "flex-start", }}>
                                                        {selectedUser?.name || `${selectedUser?.first_name} ${selectedUser?.last_name ? selectedUser?.last_name : ""}` || "Guest user"}
                                                    </Button>
                                                </Box>
                                                <Typography variant="button" component="div" color="#999" gutterBottom>
                                                    {selectedUser?.orders_count || 0} orders
                                                </Typography>
                                            </Grid>
                                            <Grid item md={3} sx={{ textAlign: "right" }}>
                                                {param.id && orderDetail?.status == "Completed" ?
                                                    null :
                                                    <Button variant='text' color='error' sx={{ minWidth: "auto" }} onClick={removeUser}>
                                                        <CloseIcon sx={{ fontSize: "18px !important" }} />
                                                    </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom> Notes  </Typography>
                                    {param.id && orderDetail?.status == "Completed" ?
                                        <Typography variant="p" component="div" gutterBottom>{body?.note || "-"}</Typography>
                                        :
                                        <FormControl fullWidth >
                                            <TextField
                                                size="small"
                                                value={body?.note}
                                                name="note"
                                                onChange={handleOnChange}
                                            />
                                        </FormControl>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Box>
                        <Divider sx={{ my: "15px" }}></Divider>
                        <Box sx={{ textAlign: "left" }}>
                            <Button variant="text" color="error" tabIndex={-1} onClick={() => navigate("/admin/gift-card")}>Cancel</Button>
                            <LoadingButton
                                sx={{ ml: 1, whiteSpace: 'nowrap' }}
                                color="primary"
                                // disabled={body?.options.length > 0 && editProduct && prodOption.filter((x) => x?.edit == false).length > 0 ? true : false}
                                onClick={saveCard}
                                loading={loading}
                                loadingIndicator="Please wait..."
                                variant="contained"
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
            </Container>

            <PopupModal
                open={openModal2}
                ModalClose={ModalClose}
                title={"Add user"}
                cancelbtn={false}
                savebtn={false}
                savebtnTxt={"Save"}
                hideFooter={true}
                savebtnFunct={""}
            >
                <AddUser type={"order"} />
            </PopupModal>
        </React.Fragment>
    );
}

export default AddGiftCard