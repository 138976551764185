import React from 'react'
import {
    Typography, Divider, TextField, FormControl,
    FormHelperText, Grid, Box, Link, InputAdornment,
} from '@mui/material';
import { useLocation, useParams } from 'react-router';

export default function Seo({ handleOnchange, body, title, description, page }) {    
    const location = window.location
    return <>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Box><Typography variant="h6" component="h6" gutterBottom>Search engine snippet</Typography></Box>
            </Grid>
            {/* <Grid item xs={4}>
                <Box sx={{ textAlign: "right" }}>
                    <Link href="#" underline="none" color="info" >Edit website SEO</Link>
                </Box>
            </Grid> */}
        </Grid>
        <Typography variant="body1" gutterBottom>Write a product title and description to view the search engine listing preview.</Typography>
        <FormControl fullWidth sx={{ my: 2 }}>
            <TextField
                label="Page title"
                type="text"
                id={title}
                name={title}
                value={body[title] || ""}
                onChange={(e)=>handleOnchange(e, 70)}
                size="small"
                sx={{ m: 0, }}
            />
            <FormHelperText >{body[title] && body[title].length || 0} of 70 characters used</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
                label="Description"
                type="text"
                // id="metafields_global_description_tag"
                // name="metafields_global_description_tag"
                id={description}
                name={description}
                value={body[description] || ""}
                onChange={(e)=>handleOnchange(e, 320)}
                size="small"
                multiline={true}
                rows={4}
                sx={{ m: 0, }}
            />
            <FormHelperText >{body[description] && body[description].length || 0} of 320 characters used</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
                label="URL and handle"
                type="text"
                id="slug"
                name="slug"
                onChange={(e)=>handleOnchange(e, 200)}
                value={body?.slug}
                size="small"
                autoComplete={'off'}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{`${location.origin}${page}/`}</InputAdornment>,
                }}
                sx={{ m: 0, }}
            />
        </FormControl>
    </>;
}
