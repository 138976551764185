import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, FormControl, Typography, TextField, Chip, Stack, IconButton, Divider, Checkbox, FormControlLabel, Avatar } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import _ from "underscore"
import { ImageCard } from "../ImageCard";
import ReactPlayer from "react-player"
import CurrencyFormat from 'react-currency-format';
import "./Variants.css"
import config from "../../../../Container/config";
import { getId } from "../../../../Container/config/Apis";
import EditIcon from '@mui/icons-material/Edit';

export default function Variants({ prodOption, handleNameChange, deleteOption, variant, addOption, doneCreate, cancelCreate, addMore, handleValueChange, deleteVal, editOption, handleVariantCheck, handleAllVariantCheck, allVariants, editVariant, editVariants, handleNameBlur, handleValueBlur, body, showEditAll, editSKUs, editProduct, changeVariantImage, moveVariant, moveOption, optionArray }) {
    return (
        // <Draggable onPosChange={getChangedPos}>
        <>
            {_.sortBy(prodOption, "position").map((data, index) => {
                let isEmpty = data.optionValue && data.optionValue.length > 0 && data.optionValue.filter((opt) => !opt?.delete && opt.value === "" || (!opt?.delete && opt.error === true)).length
                console.log("isEmpty: ", isEmpty)
                return (
                    <React.Fragment key={index} >
                        {data.edit && !data.new ?
                            <React.Fragment >
                                <Box sx={{ py: 1 }}>
                                    <ImageCard
                                        index={index}
                                        id={data.id}
                                        text={
                                            <Grid container spacing={2} columns={12} alignItems="center">
                                                <Grid item md={1} sx={{ textAlign: "center" }}>
                                                    <DragIndicatorIcon />
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Typography variant="h6" component="div" gutterBottom> {data.optionName} </Typography>
                                                    <Box>
                                                        {data.optionValue.filter((x)=>x?.value).map((val) => {
                                                            return (
                                                                <Chip key={`opt_${val.id}`} label={val.value} sx={{ mr: 1, mb:1 }} variant="outlined" />
                                                            )
                                                        })}
                                                    </Box>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <Button variant="outlined" color="primary" size="small" sx={{ float: "right" }} onClick={() => editOption(data.id)}>Edit</Button>
                                                </Grid>
                                            </Grid>
                                        }
                                        moveCard={moveOption}
                                    />
                                    <Divider sx={{ mt: 3, mb: 0 }}></Divider>
                                </Box>
                            </React.Fragment>
                            :
                            <React.Fragment >
                                <Box sx={{ pt: 1 }}>
                                    <Grid container spacing={2} columns={12} alignItems="center">
                                        <Grid item md={1} sx={{ textAlign: "center" }}></Grid>
                                        <Grid item md={10}>
                                            <Typography variant="subtitle1" component="div" gutterBottom> Name </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} columns={12} alignItems="center">
                                        <Grid item md={1} sx={{ textAlign: "center" }}>
                                            {prodOption.length > 1 ?
                                                <DragIndicatorIcon />
                                                :
                                                <DragIndicatorIcon color="disabled" />
                                            }
                                        </Grid>
                                        <Grid item md={10}>
                                            <Box>
                                                <Stack direction="row" spacing={1}>
                                                    <FormControl fullWidth sx={{ m: 0 }}>
                                                        <TextField
                                                            value={data.optionName}
                                                            autoFocus={true}
                                                            variant="outlined"
                                                            placeholder="Size"
                                                            size="small"
                                                            name="optionName"
                                                            onChange={(e) => handleNameChange(e, data.id)}
                                                            onBlur={(e) => handleNameBlur(e, data.id)}
                                                            helperText={data.error.name ? "Already used this option name." : ""}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                        <Grid item md={1}>
                                            {/* {prodOption.length > 1 && */}
                                            <IconButton aria-label="edit" color="error" tabIndex={-1} onClick={() => deleteOption(data.id)}><DeleteIcon /></IconButton>
                                            {/* } */}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ pt: 2 }}>
                                    <Grid container spacing={2} columns={12} alignItems="center">
                                        <Grid item md={1} sx={{ textAlign: "center" }}></Grid>
                                        <Grid item md={10}>
                                            <Typography variant="subtitle1" component="div" gutterBottom > Values </Typography>
                                        </Grid>
                                    </Grid>
                                    {/* <Draggable > */}
                                    {data.optionValue.filter((x) => !x?.delete).map((obj, indx) => {
                                        return (
                                            <Grid key={indx} container spacing={2} columns={12} sx={{ mb: 1 }} alignItems="center">
                                                <Grid item md={1} sx={{ textAlign: "right" }}>
                                                    {/* {data.optionValue.length > 1 &&
                                                        <DragIndicatorIcon />
                                                    } */}
                                                </Grid>
                                                <Grid item md={10}>
                                                    <Box>
                                                        <Stack direction="row" spacing={1}>
                                                            <FormControl fullWidth sx={{ m: 0 }}>
                                                                <TextField
                                                                    autoFocus={data.optionName !== "" ? true : false}
                                                                    value={obj.value}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    name="optionValue"
                                                                    onChange={(e) => handleValueChange(e, data.id, obj.id)}
                                                                    onBlur={(e) => handleValueBlur(e, data.id, obj.id)}
                                                                    helperText={obj.error ? "Already used!" : ""}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={1}>
                                                    {data.optionValue.filter((x) => !x?.delete).length > 1 &&
                                                        <IconButton aria-label="edit" tabIndex={-1} color="error" onClick={() => deleteVal(data.id, obj.id)}><DeleteIcon /></IconButton>
                                                    }
                                                </Grid>
                                            </Grid>

                                        )
                                    })}
                                    {/* </Draggable> */}
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    {data.optionValue.length < 20 &&
                                        <Grid container spacing={2} columns={12} alignItems="center">
                                            <Grid item md={1} sx={{ textAlign: "right" }}></Grid>
                                            <Grid item md={10}>
                                                {isEmpty == 1 ?
                                                    <Button color="success" disabled>+ Add more</Button>
                                                    :
                                                    <Button color="success" onClick={() => addMore(data.id)}>+ Add more</Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                                <Box>
                                    <Grid container spacing={2} columns={12} sx={{ mb: 2 }} alignItems="center">
                                        <Grid item md={1} sx={{ textAlign: "right" }}></Grid>
                                        <Grid item md={10}>
                                            {data.optionName === "" || data.error.name === true || isEmpty === 1 ?
                                                <Button variant="outlined" disabled color="primary" size="small">Done</Button>
                                                :
                                                <Button variant="outlined" color="primary" size="small" onClick={() => doneCreate(data.id, prodOption, optionArray)}>Done</Button>
                                            }
                                            <Button variant="text" sx={{ ml: 2 }} color="error" size="small" onClick={() => cancelCreate(data.id)} tabIndex={-1}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider sx={{ mt: 3, mb: 2 }}></Divider>
                            </React.Fragment>
                        }
                    </React.Fragment>
                )
            })}
            {prodOption.length < 3 && prodOption.filter((x) => x?.edit == false).length == 0 ?
                <Box>
                    <Button color="secondary" size="large" onClick={() => addOption()}>+ Add more option</Button>
                </Box>
                : prodOption.filter((x) => x?.edit == false).length > 0 ?
                    <Box>
                        <Button color="secondary" size="large" disabled>+ Add more option</Button>
                    </Box>
                    : null
            }
            {variant.length > 0 && body.options.length > 0 &&
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" component="h6" gutterBottom>Variants</Typography>
                    {!editProduct &&
                        <Grid container alignItems="center" spacing={2} columns={12} sx={{ py: 1 }}>
                            <Grid item sm={6}>
                                <FormControlLabel
                                    name="taxable"
                                    control={
                                        <Checkbox color="secondary" checked={allVariants} onChange={handleAllVariantCheck} />
                                    }
                                    label={`Showing ${variant.length}`}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                {showEditAll &&
                                    <Box sx={{ float: "right" }}>
                                        <Button variant="outlined" sx={{ mr: 2 }} color="primary" size="small" onClick={editSKUs}>Edit SKUs</Button>
                                        <Button variant="outlined" color="primary" size="small" onClick={editVariants}>Edit all</Button>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                        variant.map((obj, index) => {
                            return (
                                <React.Fragment key={`variant_${obj.variant_id}`} >
                                    <Grid container alignItems="center" spacing={2} columns={12} sx={{ py: 1 }}>
                                        <Grid item sm={6}>
                                            <Box sx={{ display: 'flex' }}>
                                                {!editProduct &&
                                                    <Checkbox color="secondary" checked={obj.checked} onChange={(e) => handleVariantCheck(e, obj)} />
                                                }
                                                {editProduct &&
                                                    <Button sx={{ minWidth: "50px" }} onClick={() => changeVariantImage(obj)}>

                                                        {getId(obj.img) === null || getId(obj.img) === undefined ?
                                                            <Avatar color="secondary" className="variant_image" alt="" src={obj.img} variant="square" sx={{ bgcolor: "#eee" }}>
                                                                {obj.img === "" &&
                                                                    <AddPhotoAlternateIcon color="info" />
                                                                }
                                                            </Avatar>
                                                            :
                                                            <Box sx={{ width: "40px", height: "40px" }}>
                                                                <ReactPlayer
                                                                    url={obj.img}
                                                                    controls={false}
                                                                    config={{
                                                                        youtube: {
                                                                            playerVars: { showinfo: 0 }
                                                                        }
                                                                    }}
                                                                    className="video_preview_product"
                                                                    width={"100%"}
                                                                    height={"100%"}
                                                                    playsinline={true}
                                                                    playbackRate={0}
                                                                    light={true}
                                                                />
                                                            </Box>
                                                        }
                                                    </Button>
                                                }
                                                <Button fullWidth className='noHover' color="secondary" variant="text" onClick={() => editVariant(obj)} sx={{ justifyContent: 'flex-start' }}>
                                                    {obj.variant && obj.variant.length > 0 && obj.variant.map((opt, ind) => {
                                                        return (
                                                            <Typography variant="p" component="strong" gutterBottom key={`opt_${ind}`} className="variants_item">
                                                                {`${opt}`}
                                                            </Typography>
                                                        )
                                                    })}
                                                    <EditIcon color="success" sx={{ fontSize: "16px !important", ml: 1 }} />
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Button fullWidth className='noHover' color="secondary" variant="text" onClick={() => editVariant(obj)} sx={{ display: 'block', textAlign: 'right' }}>
                                                <Box>
                                                    {obj.sku && obj.sku !== "undefined" && obj.sku !== "" &&
                                                        <Chip label={`SKU: ${obj.sku}`} sx={{ mr: 2 }} size="small" />
                                                    }
                                                    <Typography variant="p" component="strong" sx={{ mb: 0 }}>
                                                        <CurrencyFormat value={parseFloat(obj.price && obj.price !== "" ? obj.price : 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                    </Typography>
                                                </Box>
                                                {obj.inventory_tracked &&
                                                    <Typography variant="p" component="div" gutterBottom>
                                                        Available quantity {obj?.inventory?.available || "0"}
                                                    </Typography>
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Divider></Divider>
                                </React.Fragment>
                            )
                        })
                    }
                </Box>
            }
            {/* </Draggable> */}
        </>
    )
}