import React, { useCallback, useEffect, useState } from 'react';
import {
    Box, Button, Grid, MenuItem, Card, CardContent,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, FormHelperText, Typography, FormGroup, Select, TextField, InputBase, Container,
    Autocomplete, InputLabel

} from '@mui/material';
import { useSnackbar } from 'notistack';

import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import states from "../../../common/CommonJason/states";

import Countries from '../../../common/CommonJason/Countries';
import * as Api from '../../../../Container/config/Apis';
import config from '../../../../Container/config';
import _ from "underscore"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { dataValue } from '../../../ThemeEditor/Layout/CommonReducer';

// file drag drop  

const AddUser = (props) => {
    const param = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [body, setBody] = useState({
        accepts_marketing: false,
        email: "",
        first_name: "",
        last_name: "",
        state: "enabled",
        phone: "",
        tax_exempt: false,
        note: "",
        address: {
            first_name: "",
            last_name: "",
            address1: "",
            address2: "",
            city: "",
            company: "",
            country: "India",
            country_code: "IN",
            province: "",
            phone: "",
            zip: ""
        },
        // tags: []
    });
    const [country, setCountry] = useState(103)
    const [showError, setshowError] = useState(false)

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const handleOnchange = (e, type, length) => {
        if (e.target.value.length < length) {
            if (type === "address") {
                setBody({
                    ...body, 'address': {
                        ...body.address,
                        [e.target.name]: e.target.value
                    }
                })
            } else {
                if (e.target.name == "email" || e.target.name == "note") {
                    setBody({ ...body, [e.target.name]: e.target.value })
                } else {
                    setBody({
                        ...body, [e.target.name]: e.target.value, 'address': {
                            ...body.address,
                            [e.target.name]: e.target.value
                        }
                    })
                }
            }
        }
    }

    const modalSelectChange = (e) => {
        setBody({
            ...body, 'address': {
                ...body.address,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleCheckbox = (e) => {
        setBody({ ...body, [e.target.name]: e.target.checked })
    };
    const onSelectChange = (e) => {
        setBody({ ...body, [e.target.name]: e.target.value })
    };
    const onCountryChange = (e) => {
        console.log(Countries[e.target.value])
        setCountry(e.target.value)
        setBody({
            ...body, 'address': {
                ...body.address,
                [e.target.name]: Countries[e.target.value].label,
                'country_code': Countries[e.target.value].code
            }
        })
    };

    const handleNumberChange = (value, data, type) => {
        if (type === "address") {
            setBody({
                ...body, 'address': {
                    ...body.address,
                    'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`
                }
            })
        } else {
            let phoneVal = `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`
            // setBody({ ...body, 'phone': phoneVal })
            setBody({
                ...body, 'phone': phoneVal, 'address': {
                    ...body.address,
                    'phone': phoneVal
                }
            })
        }
    };

    console.log("body: ", body)
    // console.log("Countries: ", Countries)

    const userFn = async (api, url, data) => {
        await api(url, data).then(res => {
            console.log("res update image: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                if (props.type == "order") {
                    dispatch(dataValue({ 'new_user': data?.first_name || "" }))
                } else {
                    setTimeout(() => {
                        navigate('/admin/users')
                    }, 1000);
                }
            }
        })
    }
    function cleanFn(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || obj[propName] === []) {
                delete obj[propName];
            }
        }
        return obj
    }
    function saveUser() {
        if (body?.first_name === "" || body?.last_name === "" || body?.email === "" || body?.first_name.trim().length == 0 || body?.email.trim().length == 0 || body?.address?.phone == "" || (body?.address?.phone && body?.address?.phone.includes("+91 ") && body?.address?.phone.split("+91 ")[1].length < 10)) {
            setshowError(true)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else if (document.querySelectorAll(".form-control.invalid-number ").length > 0) {
            snack("Enter valid phone number", 'error');
        } else {
            userFn(Api.PostApi, config.USER_LIST, cleanFn(body))
        }
    }
    return (
        <Container maxWidth="lg" sx={{ px: props.type == "order" ? '0 !important' : 2 }}>
            {props.type == "order" ?
                null
                :
                <Box sx={{ mb: 2 }}>
                    <Grid sx={{ justifyContent: { md: 'center' } }} container spacing={2} columns={12}>
                        <Grid item md={8}>
                            <Button component={Link} color="secondary" variant="text" to="/admin/users" startIcon={<ArrowBackIosIcon />}> Add User </Button>
                        </Grid>
                    </Grid>
                </Box>
            }
            <Box>
                <Grid sx={{ justifyContent: 'center' }} container spacing={2} columns={12}>
                    <Grid item xs={12} md={props.type == "order" ? 12 : 8}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="h6" gutterBottom>User profile</Typography>
                                <Grid container spacing={2} columns={12} alignItems={"flex-start"}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ m: 0 }} >
                                            <TextField
                                                label="First name*"
                                                type="text"
                                                value={body.first_name}
                                                id="first_name"
                                                error={showError && (body.first_name === "" || body.first_name.trim().length == 0) ? true : false}
                                                helperText={showError && (body.first_name === "" || body.first_name.trim().length == 0) ? "First name can't be blank" : ""}
                                                name="first_name"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "", 25)}
                                                // onBlur={getprofit}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <TextField
                                                label="Last name*"
                                                type="text"
                                                id="last_name"
                                                value={body.last_name}
                                                error={showError && body.last_name === "" ? true : false}
                                                helperText={showError && body.last_name === "" ? "Last name can't be blank" : ""}
                                                size="small"
                                                name="last_name"
                                                onChange={(e) => handleOnchange(e, "", 25)}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <TextField
                                                label="Email*"
                                                type="email"
                                                id="email"
                                                value={body.email}
                                                error={showError && (body.email === "" || body.email.trim().length == 0) ? true : false}
                                                helperText={showError && (body.email === "" || body.email.trim().length == 0) ? "Email can't be blank" : ""}
                                                size="small"
                                                name="email"
                                                onChange={(e) => handleOnchange(e, "", 50)}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <PhoneInput
                                                enableSearch={true}
                                                disableAreaCodes={false}
                                                country="in"
                                                value={body.phone}
                                                inputExtrastate={{ name: 'mobile', required: true }}
                                                inputProps={{ maxLength: '17' }}
                                                onChange={(e, x) => handleNumberChange(e, x, "")}
                                                isValid={(value, data) => {
                                                    if (
                                                        value
                                                            .replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length < 10 &&
                                                        value
                                                            .replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length > 0
                                                    ) {

                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                placeholder=""
                                                countryCodeEditable={false}
                                                defaultMask=".. ... ...."
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* <Divider sx={{ my: "15px" }}></Divider>
                                <Grid container spacing={2} columns={12}>

                                    <Grid item xs={12} >
                                        <FormControlLabel
                                            name='accepts_marketing'
                                            control={<Checkbox  color="secondary"  checked={body.accepts_marketing} onChange={handleCheckbox} />}
                                            label="Agreed to receive marketing emails."
                                        />
                                        <Typography variant="caption" component="div" sx={{ opacity: "0.7" }}>*Please ask your customers before you subscribe them to your marketing emails or SMS.</Typography>
                                    </Grid>
                                </Grid> */}
                            </CardContent>
                        </Card>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" component="h6" gutterBottom>Address</Typography>
                                <Typography variant="body1" component="h6" gutterBottom>Primary address details</Typography>
                                <Grid container spacing={2} columns={12} sx={{ pt: 2 }} alignItems={"flex-start"}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ m: 0 }} >
                                            <TextField
                                                label="First name"
                                                type="text"
                                                value={body.address && body.address.first_name}
                                                id="first_name"
                                                name="first_name"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address", 25)}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <TextField
                                                label="Last name"
                                                type="text"
                                                id="last_name"
                                                value={body.address && body.address.last_name}
                                                size="small"
                                                name="last_name"
                                                onChange={(e) => handleOnchange(e, "address", 25)}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <TextField
                                                label="Company"
                                                type="text"
                                                id="company"
                                                value={body.address && body.address.company}
                                                size="small"
                                                name="company"
                                                onChange={(e) => handleOnchange(e, "address", 50)}
                                                sx={{ m: 0, width: '100%' }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ margin: "0px" }}>
                                            <PhoneInput
                                                enableSearch={true}
                                                disableAreaCodes={false}
                                                country="in"
                                                value={body.address.phone}
                                                inputExtrastate={{ name: 'mobile', required: true }}
                                                inputProps={{ maxLength: '17', inputClass: `${showError && (!body.address.phone || body.address.phone == "" || body.address.phone == "+91 " || (body.address.phone.includes("+91 ") && (body.address.phone.split("+91 ")[1].length > 0 && body.address.phone.split("+91 ")[1].length < 10))) ? "error" : ""}` }}
                                                onChange={(e, x) => handleNumberChange(e, x, "address")}
                                                isValid={(value, data) => {
                                                    if (
                                                        value
                                                            .replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length < 10 &&
                                                        value
                                                            .replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length > 0
                                                    ) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                placeholder=""
                                                countryCodeEditable={false}
                                                defaultMask=".. ... ...."
                                            />
                                            {showError && (!body.address.phone || body.address.phone == "" || body.address.phone == "+91 " || (body.address.phone.includes("+91 ") && (body.address.phone.split("+91 ")[1].length > 0 && body.address.phone.split("+91 ")[1].length < 10))) ?
                                                <Typography variant="caption" component="div" color={"error"} sx={{ ml: 2, mt: "3px" }} gutterBottom>Phone number can't be blank</Typography>
                                                : ""
                                            }
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="Address line 1"
                                                id="address1"
                                                name="address1"
                                                value={body.address && body.address.address1}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address", 80)}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="Address line 2"
                                                id="address2"
                                                name="address2"
                                                value={body.address && body.address.address2}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address", 80)}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="City"
                                                id="city"
                                                name="city"
                                                value={body?.address && body?.address.city}
                                                // error={showError && (body?.address?.city === "" || body?.address?.city.trim().length == 0) ? true : false}
                                                // helperText={showError && (body?.address?.city === "" || body?.address?.city.trim().length == 0) ? "City can't be blank" : ""}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address", 30)}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        {/* <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="State"
                                                id="province"
                                                name="province"
                                                value={body.address && body.address.province}
                                                type="text"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address", 30)}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl> */}

                                        <Select
                                            labelId="province"
                                            id="province"
                                            value={body.address && body.address.province || "State"}
                                            label="State"
                                            size="small"
                                            name="province"
                                            onChange={modalSelectChange}
                                            sx={{ m: 0, width: '100%' }}
                                        >
                                            <MenuItem value={"State"} disabled>State</MenuItem>
                                            {states.map((data, index) => {
                                                return <MenuItem key={index} value={data.name}>{data.name}</MenuItem>
                                            })}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth sx={{ m: 0 }}>
                                            <TextField
                                                label="Postal code"
                                                id="zip"
                                                name="zip"
                                                value={body.address && body.address.zip}
                                                onWheel={() => document.activeElement.blur()}
                                                onKeyDown={(e) => (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189 || e.code === "NumpadSubtract" || e.code === "NumpadAdd" || !(/[^0-9 .]/gi).test(e) || ["e", "E", "+", "-"].includes(e.key) || _.includes(Api.alphabets, e.key)) && e.preventDefault()}
                                                type="number"
                                                size="small"
                                                onChange={(e) => handleOnchange(e, "address", 7)}
                                                sx={{ m: 0, width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Country/region</InputLabel>
                                            <Select
                                                labelId="countryRegion"
                                                id="countryRegion"
                                                value={country}
                                                name="country"
                                                size="small"
                                                sx={{ mt: 0 }}
                                                onChange={onCountryChange}
                                            >
                                                <MenuItem value={103}>India</MenuItem>
                                                {/* {Countries.map((data, index) => {
                                                    return <MenuItem key={index} value={index}>{data.label}</MenuItem>
                                                })} */}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* <Box sx={{ pt: 3 }}>
                                    <Typography variant="h6" component="h6" gutterBottom>Tax exemptions</Typography>
                                    <FormControl fullWidth sx={{ m: 0 }}>

                                        <FormControlLabel name='tax_exempt' control={<Checkbox  color="secondary"  checked={body.tax_exempt} onChange={handleCheckbox} />} label="Collect tax" />
                                    </FormControl>
                                </Box> */}
                                <Box sx={{ pt: 3 }}>
                                    <Typography variant="h6" component="h6" gutterBottom>Notes</Typography>
                                    <Typography variant="body1" gutterBottom >Add notes about your user.</Typography>
                                    <FormControl fullWidth sx={{ m: 0, mt: 1 }}>
                                        <TextField
                                            label=""
                                            type="text"
                                            id="note"
                                            value={body.note}
                                            name="note"
                                            multiline={true}
                                            rows={3}
                                            onChange={(e) => handleOnchange(e, "", 300)}
                                            size="small"
                                        />
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={props.type == "order" ? 12 : 8} className={props.type == "order" ? "order_float_btn" : ""}>
                        <Divider sx={{ my: "15px" }}></Divider>
                        <Box sx={{ textAlign: "left" }}>
                            {props.type == "order" ?
                                null :
                                <Button variant="text" color="error" onClick={() => navigate("/admin/users")} tabIndex={-1}>Cancel</Button>
                            }
                            <Button variant="contained" color="primary" sx={{ ml: 1 }} onClick={saveUser}>Save</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default AddUser