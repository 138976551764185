import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, IconButton, Container, ListItemIcon, Pagination, LinearProgress, Skeleton, FormControlLabel, Checkbox,
    capitalize,
    Popover,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import ReactPlayer from "react-player"
import ErrorIcon from '@mui/icons-material/Error';

import SearchIcon from '@mui/icons-material/Search';
import noImage from '../../../../Assets/images/no_image.jpg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import productIcon from '../../../../Assets/images/no-result/product.svg';


import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Check from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { DataGrid } from '@mui/x-data-grid';
import SortIcon from '@mui/icons-material/Sort';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { tab } from '@testing-library/user-event/dist/tab';
import * as Api from "../../../../Container/config/Apis"
import config from '../../../../Container/config';
import _ from "underscore";
import { useSnackbar } from 'notistack';
import DialogAlert from '../../../common/Alerts/Dialog';
import moment from "moment"
import NoResult from '../../../common/NoResult';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import { textAlign, width } from '@mui/system';

// secrch
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.0),
    },
    marginRight: theme.spacing(2),
    marginLeft: "20px !important",
    // width: '200px',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        // background: "#f7f8fa",
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

// secrch
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}));
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <Box sx={{ mt: 10 }}>
                <Typography variant="h5" component="div">No result found!</Typography>
            </Box>
        </StyledGridOverlay>
    );
}

// dropdown
export default function GiftCards() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [showDialog, setOpen] = React.useState(false);
    const [pageLoad, setPageLoad] = React.useState(false);
    const [loadingData, setloadingData] = React.useState(true);
    const [loadingBar, setloadingBar] = React.useState(true);
    const [count, setCount] = useState(1)
    const [totalCards, setTotalCards] = useState(0)
    const [totalProduct, setTotalProduct] = useState(0)
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });
    const [activeId, setactiveId] = useState("")
    const [id, setId] = React.useState("");
    const [anchorEl, setAnchorEl] = useState({
        order: null,
        customer: null,
        sortby: null,
        date: null,
        status: null,
        paymentStatus: null,
    });
    const [activeTab, setactiveTab] = useState("all")
    const [limit, setLimit] = useState(process.env.REACT_APP_LIMIT || 20)
    const [params, setParams] = useState({
        search: "",
        status: "",
        product_type: "",
        sort_key: "",
        sort_value: "desc",
        page: 1
    })
    const [product, setProduct] = useState([
        // { id: 0, image: noImage, productname: "Product name", status: "Active", inventory: "0 in stock for 4 variants", type: "", action: "" }
    ])
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const columns2 = [
        { id: "image", label: "Product", minWidth: 300 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "inventory", label: "Inventory", minWidth: 200 },
        { id: "type", label: "Type" },
        { id: "createdAt", label: "Created at", minWidth: 150 },
        { id: "action", label: "Action", minWidth: 150 },
    ];

    const tabs = [
        { label: "All", name: "all" },
        { label: "Active", name: "active" },
        { label: "Used", name: "used" },
        { label: "Disabled", name: "disabled" },
        { label: "Expired", name: "expired" },
    ]
    const [options, setOptions] = useState([
        // { name: "Product title A-Z", key: "title", value: "asc", active: false },
        // { name: "Product title Z-A", key: "title", value: "desc", active: false },
        { name: "Created (oldest first)", key: "createdAt", value: "asc", active: false },
        { name: "Created (newest first)", key: "createdAt", value: "desc", active: false },
    ])
    const open = Boolean(anchorEl);
    const [search, setSearch] = useState('')
    const [bulkSelect, setbulkSelect] = useState(false)
    const closeDialog = () => {
        setId("")
        setOpen(false);
        setbulkSelect(false);
    }
    const handleClick = (event, type) => {        
        setAnchorEl({ ...anchorEl, [type]: event.currentTarget });
    };
    
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    //Status on change
    const clickTab = (name) => {
        setloadingBar(true)
        setactiveTab(name);
        if (name !== "all") {
            setParams({ ...params, 'page': 1, 'status': name })
            setparam("page", 1)
            setparam("status", name)
        } else {
            setParams({ ...params, 'page': 1, 'status': "" })
            deleteparam("status")
        }
    };

    //Search change
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, 'page': 1, 'search': "" })
            deleteparam("search")
        } else {
            setparam("search", e.target.value)
        }
    }

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search !== "") {
                console.log("search:", search);
                setloadingBar(true)
                setParams({ ...params, 'page': 1, 'search': search })
                setparam("search", search)
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    //Sort by on change
    const handleSortChange = (val) => {
        setloadingBar(true)
        let newArr = []
        options.map((opt) => {
            if (opt.name === val.name) {
                newArr.push({ ...opt, 'active': true })
                setParams({ ...params, "sort_key": val.key, "sort_value": val.value, 'page': 1 })
                setparam("page", 1)
                setparam("sort_key", val.key)
                setparam("sort_value", val.value)
            } else {
                newArr.push({ ...opt, 'active': false })
            }
        })
        setOptions(newArr)
        popoverClose()
    }

    //Clear sort by filter
    const clearSortBy = () => {
        setloadingBar(true)
        let newArr = []
        setParams({ ...params, 'page': 1, "sort_key": "", "sort_value": "" })
        deleteparam("sort_key")
        deleteparam("sort_value")
        options.map((opt) => {
            newArr.push({ ...opt, 'active': false })
        })
        setOptions(newArr)
    }
    const handleChangePage = (_event, newPage) => {
        setloadingBar(true)
        console.log("newPage: ", newPage)
        setParams({ ...params, "page": newPage })
        setparam("page", newPage)
    };

    const editProduct = (id) => {
        navigate(`/admin/products/${id}`)
    };

    //Get product count
    const getProductCount = (param) => {
        Api.GetApi(`${config.PRODUCTS_COUNT}${param}`).then(res => {
            console.log("res count: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                setTotalProduct(res.data.data)
                let newCount = Math.ceil(parseInt(res.data.data) / limit)
                if (newCount !== 0) {
                    setCount(newCount)
                } else {
                    setCount(1)
                }
            }
        })
    }
    console.log("params: ", params)

    //Get product list
    const getProductList = (param) => {
        Api.GetApi(`${config.GIFTCARD_LIST}${param}`).then(res => {
            setTimeout(() => {
                setloadingData(false)
                setloadingBar(false)
            }, 500);
            setTimeout(() => {
                setPageLoad(true)
            }, 1000);
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data.data.length) {
                    let countParam = searchParams
                    countParam.delete("page")
                    countParam.delete("sort_value")
                    countParam.delete("sort_key")
                }
                setProduct(res.data.data.data)
                setTotalProduct(res.data.data?.totalRecords)
                let newCount = Math.ceil(parseInt(res.data.data?.totalRecords) / limit)
                if (newCount !== 0) {
                    setCount(newCount)
                } else {
                    setCount(1)
                }
            }
        })
    }

    const onPageSizeChange = (e) => {
        setLimit(e)
    }

    const deleteFn = () => {
        Api.DeleteApi(`${config.PRODUCTS}/${id}`).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                closeDialog()
                getProductList(location.search)
            }
        })
    }
    const bulkDeleteFn = () => {
        let arr = []
        product.map((opt) => {
            if (opt.checked) {
                arr.push(opt.id)
            }
        })
        Api.PostApi(config.PRODUCTS_BULK_DELETE, { 'product_ids': arr }).then(res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                closeDialog()
                getProductList(location.search)
            }
        })
    }

    const deleteProduct = (id) => {
        setId(id)
        setContent({ ...content, 'message': 'You want to delete this product.' })
        setOpen(true)
    }
    const bulkDelete = () => {
        setbulkSelect(true)
        setContent({ ...content, 'message': 'You want to delete selected product.' })
        setOpen(true)
    }
    const confirmDialog = () => {
        if (bulkSelect) {
            bulkDeleteFn()
        } else {
            deleteFn()
        }
    }

    const openPopover = (event, id, type) => {
        console.log("anchorEl ", event, id, type)
        setAnchorEl({ ...anchorEl, [type]: event.currentTarget });
        setactiveId(id)
        // setAnchorEl(event.currentTarget);
    };

    const popoverClose = () => {
        setAnchorEl({ ...anchorEl, ['customer']: null, "sortby": null });
        setactiveId(null)
    };

    console.log("product------", product)
    console.log("anchorEl------", anchorEl)

    useEffect(() => {
        let newArr = []

        searchParams.set("page", params.page)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        console.log("location-------", location)
        if (location.search !== "") {
            getProductList(location.search)
        }
        // params['page'] = searchParams.get("page")

        setactiveTab(searchParams.get('status') || "all")
        //Set SortBy active value
        if (searchParams.get("sort_key") || searchParams.get("sort_value")) {
            options.map((obj) => {
                if (obj.key === searchParams.get("sort_key") && obj.value === searchParams.get("sort_value")) {
                    newArr.push({ ...obj, 'active': true })
                } else {
                    newArr.push({ ...obj, 'active': false })
                }
            })
            setOptions(newArr)
        }
    }, [limit, params, location.search])

    useEffect(() => {
        if (searchParams.get("search")) {
            setSearch(searchParams.get("search"))
        }
    }, [])

    return (
        <React.Fragment >
            {/* <Box className="smallContainer"> */}
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                null
            }
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={4}>
                            <Typography variant="h5" component="div"> Gift cards</Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'right', flexShrink: 0 }}>
                            <Button component={Link} to="/admin/products/gift-card/add" variant="outlined" sx={{ mr: 2 }}>Add gift card product</Button>
                            <Button component={Link} to="/admin/gift-card/add" variant="contained">Create gift card</Button>
                        </Grid>
                    </Grid>
                </Box>

                {/* <Divider sx={{ mb: 2, display: { xs: "none", md: "block" } }} /> */}
                <Card sx={{ mb: 2 }}>
                    <Box sx={{ p: { xs: 1, md: 2 }, borderBottom: "#eee 1px solid" }}>
                        <Grid container spacing={{ xs: 0, md: 2 }} columns={12}>

                            <Grid item xs={9} sm={10} md={10}>
                                <Box sx={{ display: "flex", gap: "10px", alignItems: "center", flexWrap: "wrap", }}>
                                    <Box className="responsiveFixed">
                                        <ButtonGroup variant="outlined" color="primary" aria-label="medium success button group">
                                            {tabs.map((tab) => {
                                                return (
                                                    <Button key={tab.name} variant={`${activeTab === tab.name ? 'contained' : 'outlined'}`} onClick={() => clickTab(tab.name)}>{tab.label}</Button>
                                                )
                                            })}
                                        </ButtonGroup>
                                    </Box>
                                    <Search className='filter_search_panel ml-0'>
                                        <SearchIconWrapper>
                                            <SearchIcon color="grey" />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search', value: search }}
                                            // onBlur={handleSearchBlur}
                                            // onKeyUp={handleKeyPress}
                                            onChange={handleSearchChange}
                                        />
                                    </Search>
                                </Box>
                            </Grid>
                            <Grid item xs={3} sm={2} md={2}>
                                <Box sx={{ textAlign: "Right" }}>
                                    {searchParams.get("sort_key") || searchParams.get("sort_value") ?
                                        <Button variant='text' color="error" size="small" sx={{ mr: { md: 2 }, minWidth: { xs: "30px", md: "64px" } }} endIcon={<ClearIcon />} onClick={clearSortBy}>
                                            <Box sx={{ display: { xs: "none", md: "block" } }}>Clear</Box>
                                        </Button>
                                        : null
                                    }
                                    <Button
                                        id="sortButton"
                                        aria-controls="sortButtonArea"
                                        aria-haspopup="true"
                                        aria-expanded={anchorEl.sortby ? 'true' : undefined}
                                        variant="text"
                                        color="secondary"
                                        disableElevation
                                        onClick={(e)=>handleClick(e, "sortby")}
                                        sx={{ minWidth: { xs: "30px", md: "64px" } }}
                                        endIcon={<KeyboardArrowDownIcon sx={{ display: { xs: "none", md: "block" } }} />}>
                                        <Box sx={{ display: { xs: "none", md: "block" } }}>Sort by</Box>
                                        <Box sx={{ display: { xs: "block", md: "none" } }}><SortIcon /></Box>
                                    </Button>
                                    <StyledMenu
                                        id="sortButtonArea"
                                        MenuListProps={{ 'aria-labelledby': 'sortButton', }}
                                        anchorEl={anchorEl.sortby}
                                        open={anchorEl.sortby}
                                        onClose={popoverClose}>
                                        {options.map((opt) => {
                                            return (
                                                <MenuItem key={opt.name} onClick={() => handleSortChange(opt)} disableRipple className={`sort_list ${opt.active ? "active" : ""}`} sx={{ fontSize: "12px" }}>
                                                    {opt.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </StyledMenu>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {pageLoad ?
                        <Box sx={{ width: '100%', minHeight: "100px" }}>
                            {product.length > 0 ?
                            <TableContainer>
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell padding="0">
                                                    <FormControlLabel sx={{ mr: 0 }} control={<Checkbox color="secondary" checked={false} onChange={(e) => checkboxChange(e, "all")} name="all" />} />
                                                </TableCell> */}
                                            <TableCell style={{ minWidth: 200 }}>
                                                {product.filter((x) => x.checked).length > 0
                                                    ?
                                                    <Button variant='outlined' color="error" onClick={bulkDelete}><DeleteIcon sx={{ fontSize: "18px !important", mr: 1 }} />{" "} Delete selected</Button>
                                                    :
                                                    "Code"
                                                }
                                            </TableCell>
                                            <TableCell style={{ minWidth: 100 }}>
                                                Status
                                            </TableCell>
                                            <TableCell style={{ minWidth: 200 }}>
                                                Customer
                                            </TableCell>
                                            <TableCell style={{ minWidth: 150 }}>
                                                Created at
                                            </TableCell>
                                            <TableCell style={{ minWidth: 100, textAlign: "right" }}>
                                                Balance / Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {product.map((row) => {
                                            return (
                                                <TableRow hover role="checkbox">
                                                    {/* <TableCell padding="0">
                                                            <FormControlLabel sx={{ mr: 0 }} control={<Checkbox color="secondary" checked={row.checked ? true : false} onChange={(e) => checkboxChange(e, row)} />} />
                                                        </TableCell> */}
                                                    <TableCell size="small">
                                                        <NavLink to={`/admin/gift-card/${row.gift_card_id}`} style={{ display: "block" }}>
                                                            {row.code}
                                                        </NavLink>
                                                    </TableCell>
                                                    <TableCell size="small">
                                                        <Chip label={row.status} sx={{ textTransform: "capitalize" }} variant={"contained"} color={(row.status == "expired" || row.status == "disabled") ? "error" : row.status == "used" ? "primary" : row.status == "redeemed" ? "warning": "success"} disabled={row.status == "disabled"} />
                                                    </TableCell>
                                                    <TableCell size="small">
                                                        <>
                                                            <Button aria-describedby={row.gift_card_id} variant="text" color="secondary" onClick={(e) => openPopover(e, row.gift_card_id, "customer")} endIcon={<ArrowDropDownIcon />}>
                                                                <span className='text-truncate'>{row.customer?.first_name ? `${row.customer?.first_name} ${row.customer?.last_name ? row.customer?.last_name : ""}` : "Guest user"}</span>
                                                            </Button>
                                                            <Popover
                                                                id={row.gift_card_id}
                                                                open={activeId == row.gift_card_id}
                                                                anchorEl={anchorEl.customer}
                                                                onClose={popoverClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }} >
                                                                <Typography component="div" sx={{ p: 2 }}>
                                                                    <Typography variant="caption" sx={{ mb: 1 }} component="div">
                                                                        <a href={`mailto:${row.customer?.email || ''}`} color="secondary">{row.customer?.email || ''}</a>
                                                                    </Typography>
                                                                    <NavLink to={`/admin/users/${row?.customer_id}`} variant="text" className={"link"} style={{ fontSize: "12px" }}>View user</NavLink>
                                                                </Typography>
                                                            </Popover>
                                                        </>
                                                    </TableCell>
                                                    <TableCell size="small">
                                                        <Box sx={{ whiteSpace: "nowrap" }}>
                                                            {moment(row.createdAt).format("lll") || ""}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell size="small">
                                                        <Box sx={{ whiteSpace: "nowrap", textAlign: "right" }} className="action_btns">
                                                            <CurrencyFormat value={parseFloat(row.balance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                            {" / "}
                                                            <CurrencyFormat value={parseFloat(row.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                                <Box sx={{ my: 6, textAlign: "center" }}>
                                    <NoResult
                                        icon={productIcon}
                                        title={"No gift card found."}
                                        subtitle={"Add gift cards to your store and share them with your customers."}
                                        btn={true}
                                        btnText={"Add gift card"}
                                        link={"/admin/gift-card/add"}
                                    />
                                </Box>
                            }

                            {/* {product.length > 0 ? */}
                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px", flexWrap: "wrap", p: 2, alignItems: "center" }}>
                                <Typography variant='body2' component={"div"} sx={{ mb: 0 }}>Showing {product.length} of {totalProduct} products</Typography>
                                <Pagination count={count} page={params.page} showFirstButton showLastButton onChange={handleChangePage} />
                            </Box>
                            {/* : null
                            } */}
                        </Box>
                        : null
                    }
                </Card>
            </Container>
            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
            />
        </React.Fragment>
    )
}

